import { useEffect, useState } from 'react';
import useProjects from '../../../../hooks/useProjects';
import LoadingController from '../../../../components/utils/LoadingController';
import DynamicTable from '../../../../components/builders/DynamicTable';
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useOperation } from '../../../../providers/OperationProvider';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ProjectPrint from '../prints/ProjectPrint';
import PermissionGuard from '../../../../guards/PermissionGuard';
import { IProjectUserData } from '../../../../interfaces/projects.interface';
import { IConsent } from '../../../../interfaces/consents.interface';
import { IQuestion } from '../../../../interfaces/questions.interface';
import { OPERATIONS } from '../../../../utils/constants';
import useConsents from '../../../../hooks/useConsents';
import ConfirmationDialog from '../../../../components/dialogs/ConfirmationDialog';
import moment from 'moment';
import { MOBILE_DEVICE_SIGNATURE, YES_NO } from '../../../../constants/common';
import { OutlineButton } from '../../../../layouts/styled/buttons';
import { Fingerprint } from '@mui/icons-material';
import { getBlockExplorer } from '../../../../utils/getLink';

interface Props {
  projectHash: string;
  projectTitle: string;
  consents: IConsent[];
  questions: IQuestion[];
  taxId: string;
  refreshData: (value: number) => void;
}

export default function UsersInProjectTable({
  projectHash,
  projectTitle,
  consents,
  questions,
  taxId,
  refreshData: setRefreshProjects,
}: Props) {
  const { t } = useTranslation();
  const { setOperation } = useOperation();

  const [areSignaturesLoading, setAreSignaturesLoading] =
    useState<boolean>(false);
  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState<boolean>(false);
  const [deleteDialog, setDeleteDialog] = useState<IProjectUserData | null>(
    null,
  );
  const [users, setUsers] = useState<IProjectUserData[]>([]);
  const [projectActivity, setProjectActivity] = useState<IProjectUserData>();
  const { getProjectOverview, removeUserFromProject } = useProjects();
  const { getConsentSignatureByUserHash } = useConsents();
  const [refresh, setRefresh] = useState<number>(0);

  useEffect(() => {
    if (isPrintDialogOpen && projectActivity) {
      fetchSignatures(projectActivity);
    }
  }, [isPrintDialogOpen]);

  const fetchSignatures = async (projectActivity: IProjectUserData) => {
    setAreSignaturesLoading(true);
    const operations = await Promise.all(
      projectActivity.operations.map(async (el) => ({
        ...el,
        signature:
          el.signatureType === MOBILE_DEVICE_SIGNATURE
            ? await getConsentSignatureByUserHash(
                el.consentHash,
                projectActivity.user.userHash,
                `${el.timestamp}`,
              )
            : undefined,
      })),
    );
    setProjectActivity({ ...projectActivity, operations });
    setAreSignaturesLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);

  const fetchData = async () => {
    try {
      setAreSignaturesLoading(true);
      setUsers(await getProjectOverview(projectHash));
    } catch (error) {
      console.error('Error fetching questions:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setAreSignaturesLoading(false);
    }
  };

  const handleDownloadSignature = async (item: IProjectUserData) => {
    const file = await getConsentSignatureByUserHash(
      item.operations.filter(
        (el) => el.signatureType === MOBILE_DEVICE_SIGNATURE,
      )[0].consentHash,
      item.user.userHash,
      item.operations[0].timestamp.toString(),
      true,
    );
    const link = document.createElement('a');
    link.href = file;
    link.download = `signature-${item.user.userHash}.json`;
    link.click();
  };

  const onRemoveClick = async (item: IProjectUserData) => {
    try {
      setAreSignaturesLoading(true);
      await removeUserFromProject(projectHash, item.user.userHash);
      setRefresh(Date.now());
    } catch (error) {
      console.error('Cannot remove user from the project:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  const getInvitedByLabel = (item: IProjectUserData) => {
    if (item.invitedBy) {
      const { email, firstName, secondName } = item.invitedBy;
      const parts = [];
      if (email) parts.push(email);
      if (firstName) parts.push(firstName);
      if (secondName) parts.push(secondName);
      return parts.join(', ');
    }
    return '';
  };

  const getConsentColumns = () => {
    const consentColumns = consents
      .map((el) => el.title)
      .map((title) => [
        {
          label: `${title}`,
          accessor: (item: IProjectUserData) => {
            const consent =
              item.operations &&
              item.operations.find((item) => item.title === title);
            return consent && 'isSigned' in consent
              ? consent.isSigned
                ? t('common.tables.content.signed')
                : t('common.tables.content.withdrawn')
              : t('common.tables.content.notSigned');
          },
        },
        {
          label: t('common.tables.header.notes'),
          accessor: (item: IProjectUserData) => {
            const consent =
              item.operations &&
              item.operations.find((item) => item.title === title);
            return consent && consent.notes ? (
              consent.notes
            ) : (
              <Chip
                label={t('common.no-data')}
                color="default"
                variant="outlined"
                size="small"
              />
            );
          },
        },
        {
          label: t('common.tables.header.operationLog'),
          type: 'txnHash',
          accessor: (item: IProjectUserData) => {
            const consent =
              item.operations &&
              item.operations.find((item) => item.title === title);
            return consent && consent.txnHash ? (
              <Typography variant={'body1'}>
                <a
                  href={`${getBlockExplorer(consent.timestamp)}${
                    consent.txnHash
                  }`}
                  target="_blank"
                >
                  {consent.txnHash.slice(0, 4)}...{consent.txnHash.slice(-3)}
                </a>
              </Typography>
            ) : (
              <Chip
                label={t('common.no-data')}
                color="default"
                variant="outlined"
                size="small"
              />
            );
          },
        },
        {
          label: t('common.tables.header.created-at'),
          accessor: (item: IProjectUserData) => {
            const consent =
              item.operations &&
              item.operations.find((item) => item.title === title);
            return consent && consent.timestamp ? (
              moment.unix(consent.timestamp).format('DD-MM-YYYY HH:mm:ss')
            ) : (
              <Chip
                label={t('common.no-data')}
                color="default"
                variant="outlined"
                size="small"
              />
            );
          },
        },
      ]);
    return consentColumns.flat();
  };

  const getAnswerColumns = (data: IProjectUserData[]) => {
    let answerNames: string[] = data.reduce((names: string[], history) => {
      if (history.answers) {
        history.answers.forEach((answer) => {
          if (!names.includes(answer.question)) {
            names.push(answer.question);
          }
        });
      }
      return names;
    }, []);

    const answerColumns = answerNames.map((answerName) => ({
      label: `${answerName}`,
      accessor: (item: IProjectUserData) => {
        const answers = item.answers;
        if (answers) {
          const answer = answers.find(
            (answer) => answer.question === answerName,
          );
          return answer
            ? answer.type === YES_NO && answer.answer
              ? t(`common.forms.labels.${answer.answer}`)
              : answer.answer
            : '';
        }
        return '';
      },
      type: 'answer',
    }));

    return answerColumns;
  };

  return (
    <>
      {areSignaturesLoading ? (
        <LoadingController />
      ) : users.length > 0 ? (
        <DynamicTable
          title={t('common.labels.users-in-project-overview-table')}
          data={users}
          columns={[
            {
              label: t('common.tables.header.email'),
              accessor: 'user',
              type: 'userEmail',
            },
            {
              label: t('common.tables.header.first-name'),
              accessor: 'user',
              type: 'userFirstName',
            },
            {
              label: t('common.tables.header.second-name'),
              accessor: 'user',
              type: 'userSecondName',
            },
            {
              label: t('common.tables.header.phone'),
              accessor: 'user',
              type: 'userPhone',
            },
            ...(getAnswerColumns(users) as any),
            ...getConsentColumns(),
            {
              label: t('common.tables.header.invited-by'),
              accessor: getInvitedByLabel,
              type: 'invitedBy',
            },
          ]}
          onHeaderClick={() => {}}
          onItemClick={() => {}}
          onExpandClick={() => {}}
          actions={[
            (item) => (
              <IconButton
                color="primary"
                onClick={() => {
                  setProjectActivity(item);
                  setIsPrintDialogOpen(true);
                }}
              >
                <PrintIcon />
              </IconButton>
            ),
            (item) =>
              item.operations &&
              item.operations.some(
                (operation) =>
                  operation.signatureType === MOBILE_DEVICE_SIGNATURE,
              ) && (
                <IconButton
                  color="primary"
                  onClick={() => {
                    handleDownloadSignature(item);
                  }}
                >
                  <Fingerprint />
                </IconButton>
              ),
            (item) => (
              <PermissionGuard
                permissions={['ENTERPRISE_REMOVE_USER_FROM_PROJECT']}
                taxIds={[taxId]}
              >
                <IconButton
                  color="primary"
                  onClick={() => setDeleteDialog(item)}
                >
                  <DeleteIcon />
                </IconButton>
              </PermissionGuard>
            ),
          ]}
        />
      ) : (
        <></>
      )}
      <ConfirmationDialog
        open={!!deleteDialog}
        description={t('common.confirm-remove-project-user')}
        handleClose={() => {
          setDeleteDialog(null);
        }}
        onConfirmClick={() => {
          onRemoveClick(deleteDialog!);
          setDeleteDialog(null);
          setRefreshProjects(Date.now());
        }}
      />
      {projectActivity && consents.length > 0 && (
        <Dialog
          open={isPrintDialogOpen}
          onClose={() => setIsPrintDialogOpen(false)}
        >
          <DialogTitle>
            <Typography variant={'h5'}>
              {t('common.document-generation')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            {!areSignaturesLoading ? (
              <PDFDownloadLink
                document={
                  <ProjectPrint
                    projectTitle={projectTitle}
                    questions={questions}
                    consents={consents}
                    projectOverview={projectActivity}
                  />
                }
                fileName={`project-${projectActivity.user.userHash}.pdf`}
              >
                {({ blob, url, loading, error }) => (
                  <OutlineButton
                    sx={{ margin: '0 auto', marginTop: '10px' }}
                    onClick={() => {
                      setIsPrintDialogOpen(false);
                    }}
                  >
                    {t('common.button.download-btn')}
                  </OutlineButton>
                )}
              </PDFDownloadLink>
            ) : (
              <LoadingController />
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

import { useEffect, useState } from 'react';
import NodeContainer from '../../../layouts/containers/NodeContainer';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Tab } from '@mui/material';
import {
  ITEMS_PER_PAGE,
  SORTING,
  SORTING_OPTIONS,
  Tabs,
} from '../../../utils/constants';
import SortButton from '../../../components/actions/SortButton';
import LoadingController from '../../../components/utils/LoadingController';
import NoData from '../../../components/utils/NoData';
import Pagination from '@mui/material/Pagination';
import { useOperation } from '../../../providers/OperationProvider';
import { ISorting } from '../../../interfaces/sorting.interface';
import { TabContext, TabList } from '@mui/lab';
import ConsentItem from './items/ConsentItem';
import { ClassicButton, ClassicInput } from '../../../layouts/styled/buttons';
import { IConsentResponse } from '../../../interfaces/consents.interface';
import useConsents from '../../../hooks/useConsents';
import useQuestions from '../../../hooks/useQuestions';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import { OPERATIONS } from '../../../utils/constants';
import { IQuestionResponse } from '../../../interfaces/questions.interface';
import {
  DEFAULT_PAGE_INDEX,
  EMPTY_DATA,
  SEARCH_INPUT_DELAY,
  TRANSACTION_CHECK_TIMEOUT,
} from '../../../constants/common';
import { useSearchParams } from 'react-router-dom';
import useUtils from '../../../hooks/useUtils';
import TransactionStatus from '../../../components/TransactionStatus';
import QuestionItem from './items/QuestionItem';

const tabs = [
  {
    value: Tabs.CONSENTS,
    label: 'common.tabs.consents',
    sortingOptions: [
      SORTING.CREATED_AT_DESC,
      SORTING.CREATED_AT_ASC,
      SORTING.TITLE_ASC,
      SORTING.TOTAL_SIGNS_DESC,
      SORTING.ACTIVE_SIGNS_DESC,
    ],
  },
  {
    value: Tabs.QUESTIONS,
    label: 'common.tabs.questions',
    sortingOptions: [
      SORTING.CREATED_AT_DESC,
      SORTING.CREATED_AT_ASC,
      SORTING.TITLE_ASC,
    ],
  },
];

const UserDataPage = () => {
  const { t } = useTranslation();
  const { setOperation } = useOperation();
  const { getOwnConsents, withdrawConsents } = useConsents();
  const { removeAnswers, getOwnQuestions } = useQuestions();
  const { getTxnStatus } = useUtils();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [consents, setConsents] = useState<IConsentResponse>(EMPTY_DATA);
  const [questions, setQuestions] = useState<IQuestionResponse>(EMPTY_DATA);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [txnHash, setTxnHash] = useState<string>();
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [sorting, setSorting] = useState<ISorting>(SORTING.CREATED_AT_DESC);
  const [tab, setTab] = useState<string>(tabs[0].value);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchTimeout, setSearchTimeout] = useState<number>();
  const [refresh, setRefresh] = useState<number>(0);

  useEffect(() => {
    if (refresh) fetchData(pageIndex, sorting, searchQuery);
  }, [refresh]);

  useEffect(() => {
    const sorting = searchParams.get('sorting');
    const tab = searchParams.get('tab');
    setSearchQuery(searchParams.get('searchQuery') || '');
    setPageIndex(Number(searchParams.get('pageIndex')) || DEFAULT_PAGE_INDEX);
    setSorting(
      SORTING_OPTIONS.find((el) => el.value === sorting) ||
        SORTING.CREATED_AT_ASC,
    );
    setTab(tab === Tabs.QUESTIONS ? Tabs.QUESTIONS : Tabs.CONSENTS);
    setRefresh(Date.now());
  }, [searchParams]);

  const onTabClick = (tab: Tabs) => {
    setSearchParams({ tab });
  };

  const fetchData = async (
    pageIndex: number,
    sorting: ISorting,
    searchQuery: string,
  ) => {
    setIsLoading(true);
    try {
      if (tab === tabs[0].value)
        setConsents(
          await getOwnConsents({
            pageIndex: pageIndex - 1,
            sorting: sorting.value,
            searchQuery,
          }),
        );
      if (tab === tabs[1].value)
        setQuestions(
          await getOwnQuestions({
            pageIndex: pageIndex - 1,
            sorting: sorting.value,
            searchQuery,
          }),
        );
    } catch (error) {
      console.error('Error fetching consents:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!txnHash) {
      setIsDialogOpen(false);
    } else {
      checkTransaction(txnHash);
    }
  }, [txnHash]);

  const checkTransaction = async (txnHash: string) => {
    try {
      const status = await getTxnStatus(txnHash);
      if (status === 2) {
        setTimeout(() => {
          checkTransaction(txnHash);
        }, TRANSACTION_CHECK_TIMEOUT);
      } else {
        setTxnHash(undefined);
        setRefresh(Date.now());
      }
    } catch (e) {
      console.error('Cannot check transaction', e);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    }
  };

  const onRevokeAllClick = async () => {
    try {
      setIsLoading(true);
      if (tab === tabs[0].value) {
        const txnHash = await withdrawConsents(
          consents.data
            .filter((el) => el.state === 2)
            .map((el) => el.consentHash),
        );
        setTxnHash(txnHash);
      } else {
        await removeAnswers(questions.data.map((el) => el.questionHash));
      }
      setRefresh(Date.now());
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message:
          tab === tabs[0].value
            ? t('pages.operations.messages.consent-revoked')
            : t('pages.operations.messages.question-revoked'),
      });
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsLoading(false);
      setIsDialogOpen(false);
    }
  };

  const onRemoveAnswerClick = async (questionHash: string) => {
    try {
      await removeAnswers([questionHash]);
      setRefresh(Date.now());
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.question-revoked'),
      });
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    pageIndex: number,
  ) => {
    setSearchParams({
      pageIndex: `${pageIndex}`,
      tab,
      sorting: sorting.value,
      searchQuery,
    });
  };

  const onSortClick = async (sorting: ISorting) => {
    setSearchParams({
      tab,
      searchQuery,
      sorting: sorting.value,
    });
  };

  const handleSearch = (searchQuery: string) => {
    clearTimeout(searchTimeout);
    setSearchQuery(searchQuery);
    const timeout = setTimeout(() => {
      setSearchParams({
        tab,
        sorting: sorting.value,
        searchQuery,
      });
    }, SEARCH_INPUT_DELAY);
    setSearchTimeout(timeout);
  };

  return (
    <Box className="App-container">
      <NodeContainer
        title={t('pages.userData.title')}
        cta={
          <ClassicInput
            fullWidth
            label={t('common.search-label.projects')}
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
        }
        subtitle={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexWrap: { xs: 'wrap', md: 'nowrap' },
              justifyContent: { xs: 'center', md: 'space-between' },
            }}
          >
            <TabContext value={tab}>
              <Box>
                <TabList onChange={(event, value) => onTabClick(value)}>
                  {tabs.map((tab, index) => (
                    <Tab key={index} label={t(tab.label)} value={tab.value} />
                  ))}
                </TabList>
              </Box>
            </TabContext>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {((tab === tabs[0].value && consents.total > 0) ||
                (tab === tabs[1].value && questions.total > 0)) && (
                <SortButton
                  sortOptions={
                    tabs.find((el) => el.value === tab)!.sortingOptions
                  }
                  onSelectSort={onSortClick}
                  selectedSort={sorting}
                />
              )}
              <ClassicButton
                sx={{ mt: 0, whiteSpace: 'nowrap' }}
                key="grant-revoke-button"
                onClick={() => {
                  setIsDialogOpen(true);
                }}
                disabled={
                  (tab === tabs[0].value &&
                    !consents.data.some((el) => el.state === 2)) ||
                  (tab === tabs[1].value && questions.total === 0)
                }
              >
                {t('common.button.revoke-all-btn')}
              </ClassicButton>
            </Box>
          </Box>
        }
      >
        {isLoading || txnHash ? (
          <>
            {txnHash ? (
              <TransactionStatus txnHash={txnHash} />
            ) : (
              <LoadingController />
            )}
          </>
        ) : tab === tabs[0].value ? (
          consents.total === 0 ? (
            <NoData />
          ) : (
            <>
              <Grid container spacing={2} sx={{ py: 5 }}>
                {consents.data.map((consent, index) => (
                  <ConsentItem
                    key={index}
                    consent={consent}
                    setRefresh={(value) => setRefresh(value)}
                  />
                ))}
              </Grid>
              <Pagination
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                count={Math.ceil(consents.total / ITEMS_PER_PAGE)}
                page={pageIndex}
                onChange={handlePageChange}
                style={{ margin: '10px 0' }}
              />
            </>
          )
        ) : questions.total === 0 ? (
          <NoData />
        ) : (
          <>
            <Grid container spacing={2} sx={{ py: 5 }}>
              {questions.data.map((question, index) => (
                <Grid item xs={12} md={4}>
                  <QuestionItem
                    key={index}
                    question={question}
                    onRevokeClick={() =>
                      onRemoveAnswerClick(question.questionHash)
                    }
                  />
                </Grid>
              ))}
            </Grid>
            <Pagination
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              count={Math.ceil(questions.total / ITEMS_PER_PAGE)}
              page={pageIndex}
              onChange={(event, value) => {
                setPageIndex(value);
                setRefresh(Date.now());
              }}
              style={{ margin: '10px 0' }}
            />
          </>
        )}

        <ConfirmationDialog
          open={isDialogOpen}
          description={
            tab === tabs[0].value
              ? t('common.confirm-consents-revoke')
              : t('common.confirm-questions-revoke')
          }
          handleClose={() => {
            setIsDialogOpen(false);
          }}
          onConfirmClick={onRevokeAllClick}
        />
      </NodeContainer>
    </Box>
  );
};

export default UserDataPage;

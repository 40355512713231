import { ReactNode } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Chip,
  Checkbox,
  Tooltip,
} from '@mui/material';
import { COLORS } from '../../utils/constants';
import { t } from 'i18next';
import { ISort } from '../../interfaces/sorting.interface';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Scrollbar from '../utils/Scrollbar';
import React from 'react';
import { CUSTOM, OPEN, YES_NO } from '../../constants/common';
import { ITag } from '../../interfaces/tags.interface';

interface QuestionHistoryAccessor<T> {
  (item: T): any;
}

interface DynamicTableProps<T> {
  data: T[];
  title: string;
  columns: Column<T>[];
  onHeaderClick: (column: string) => void;
  onItemClick: (item: T) => void;
  onExpandClick: (item: T) => void;
  sort?: ISort;
  actions?: ((item: T) => ReactNode)[];
  innerData?: ReactNode;
  expandedRow?: string;
}

export interface Column<T> {
  label: string;
  type: string;
  accessor: keyof T | QuestionHistoryAccessor<T>;
  formatter?: (value: any) => ReactNode;
}

const tableHeadStyle = {
  backgroundColor: COLORS.specialColor,
  color: COLORS.secondaryColor,
  fontWeight: 'bold',
};

const tableHeadCellStyle = {
  fontWeight: 'bold',
  cursor: 'pointer',
};

const DynamicTable = <T extends Record<string, any>>({
  data,
  title,
  columns,
  onHeaderClick,
  onItemClick,
  onExpandClick,
  sort,
  actions,
  innerData,
  expandedRow,
}: DynamicTableProps<T>) => {
  const formatCellValue = (column: string, value: any) => {
    if (column === 'isSigned') {
      return value
        ? t('common.tables.content.signed')
        : t('common.tables.content.withdrawn');
    } else if (column === 'tags') {
      return (
        value && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '10px',
              overflow: 'scroll',
              height: '45px',
            }}
          >
            {value.map((tag: string | ITag, index: number) =>
              typeof tag === 'string' ? (
                <Chip
                  label={tag}
                  key={index}
                  color="default"
                  variant="outlined"
                  size="small"
                  style={{ marginLeft: '8px' }}
                />
              ) : (
                <Tooltip title={tag.name} placement="top">
                  <Chip
                    label={tag.tag}
                    key={index}
                    color="default"
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: '8px' }}
                  />
                </Tooltip>
              ),
            )}
          </Box>
        )
      );
    } else if (column === 'draft' || column === 'activated') {
      return <Checkbox checked={value} disabled />;
    } else if (column === 'version') {
      return (
        <Chip
          label={`v.${value}`}
          color="default"
          variant="outlined"
          size="small"
        />
      );
    } else if (column === 'operationBy' || column === 'operationSubject') {
      return value ? (
        <Typography variant={'body1'}>{value.email}</Typography>
      ) : (
        <Chip
          label={t('common.no-data')}
          color="default"
          variant="outlined"
          size="small"
        />
      );
    } else if (column === 'signatureType') {
      return t(`common.forms.labels.${value}`);
    } else if (column === 'expand') {
      return expandedRow === value ? <ExpandLessIcon /> : <ExpandMoreIcon />;
    } else if (column === 'userEmail') {
      return (
        value.email ?? (
          <Chip
            label={t('common.no-data')}
            color="default"
            variant="outlined"
            size="small"
          />
        )
      );
    } else if (column === 'userFirstName') {
      return (
        value.firstName ?? (
          <Chip
            label={t('common.no-data')}
            color="default"
            variant="outlined"
            size="small"
          />
        )
      );
    } else if (column === 'userSecondName') {
      return (
        value.secondName ?? (
          <Chip
            label={t('common.no-data')}
            color="default"
            variant="outlined"
            size="small"
          />
        )
      );
    } else if (column === 'userPhone') {
      return (
        value.phone ?? (
          <Chip
            label={t('common.no-data')}
            color="default"
            variant="outlined"
            size="small"
          />
        )
      );
    } else if (column === 'userPersonalId') {
      return (
        value.personalId ?? (
          <Chip
            label={t('common.no-data')}
            color="default"
            variant="outlined"
            size="small"
          />
        )
      );
    } else if (column === 'status') {
      return (
        <Chip
          label={
            value[0].isSigned
              ? t('common.status.active')
              : t('common.status.inactive')
          }
          color="default"
          variant="outlined"
          size="small"
        />
      );
    } else if (column === 'type') {
      return (
        <Chip
          label={
            value === YES_NO
              ? t('common.forms.labels.yes_no-item')
              : value === CUSTOM
              ? t('common.forms.labels.custom-item')
              : value === OPEN
              ? t('common.forms.labels.open-item')
              : value
          }
        />
      );
    } else if (column === 'object') {
      <Tooltip title={value} placement="top">
        <Typography
          variant={'body1'}
          sx={{
            maxWidth: '10rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          {value}
        </Typography>
      </Tooltip>;
    }

    if (!value) {
      return (
        <Chip
          label={t('common.no-data')}
          color="default"
          variant="outlined"
          size="small"
        />
      );
    }

    return value.length > 18 ? (
      <Tooltip title={value} placement="top">
        <Typography
          variant={'body1'}
          sx={{
            minWidth: '9rem',
            maxWidth: '10rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          {value}
        </Typography>
      </Tooltip>
    ) : (
      value
    );
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Typography component="h5" variant="h5">
          {title}
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          mb: 4,
          overflowX: 'scroll',
        }}
      >
        <Scrollbar>
          <Table>
            <TableHead sx={tableHeadStyle}>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      ...tableHeadCellStyle,
                      minWidth:
                        column.label.length > 26
                          ? '320px'
                          : `${column.label.length * 12}px`,
                    }}
                    onClick={() => onHeaderClick(column.type)}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {column.label}
                      {!!sort && sort.column === column.type && (
                        <>
                          {sort.direction === 'ASC' ? (
                            <ArrowUpwardIcon
                              sx={{ fontSize: '16px', ml: '5px' }}
                            />
                          ) : (
                            <ArrowDownwardIcon
                              sx={{ fontSize: '16px', ml: '5px' }}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </TableCell>
                ))}
                {actions && actions.length > 0 && (
                  <TableCell key={columns.length} sx={tableHeadCellStyle}>
                    {t('common.tables.header.actions')}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => {
                let expandValue;
                return (
                  <React.Fragment key={index}>
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor:
                          item.isSeen === false ? COLORS.lightGrey : '',
                        borderLeft:
                          item.isSeen === false ? '1px red solid' : '',
                      }}
                    >
                      {columns.map((column, index) => {
                        if (column.type === 'expand') {
                          expandValue = item[column.accessor as keyof T];
                        }
                        return (
                          <TableCell
                            key={index}
                            sx={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              if (column.type === 'expand') {
                                onExpandClick(item);
                              } else {
                                onItemClick(item);
                              }
                            }}
                          >
                            <Box
                              sx={{
                                maxHeight: '50px',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                overflow: 'scroll',
                                WebkitLineClamp: 2,
                              }}
                            >
                              {typeof column.accessor === 'function'
                                ? column.accessor(item)
                                : column.formatter
                                ? column.formatter(item[column.accessor])
                                : formatCellValue(
                                    column.type,
                                    item[column.accessor],
                                  )}
                            </Box>
                          </TableCell>
                        );
                      })}
                      {actions && actions.length > 0 && (
                        <TableCell sx={{ display: 'flex' }}>
                          {actions.map((action, actionIndex) => (
                            <span key={actionIndex}>{action(item)}</span>
                          ))}
                        </TableCell>
                      )}
                    </TableRow>
                    {!!expandedRow && expandedRow === expandValue && (
                      <TableRow>
                        <TableCell colSpan={columns.length + (actions ? 1 : 0)}>
                          {innerData}
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </>
  );
};

export default DynamicTable;

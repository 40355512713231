import api from '../api';
import { useAuth } from '../providers/AuthProvider';
import { ITag, ITagRequest } from '../interfaces/tags.interface';

interface TagContextType {
  getTags: (taxIds: string[], searchQuery?: string) => Promise<ITag[]>;
  addTag: (taxId: string, item: ITagRequest) => Promise<void>;
  removeTag: (taxId: string, item: ITagRequest) => Promise<void>;
}

const useTags = (): TagContextType => {
  const auth = useAuth();
  const getTags = async (
    taxIds: string[],
    searchQuery?: string,
  ): Promise<ITag[]> => {
    try {
      const response = await api.get<ITag[]>(`/tags`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        params: {
          taxIds,
          searchQuery,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const addTag = async (taxId: string, item: ITagRequest): Promise<void> => {
    try {
      await api.put(
        '/tags/add',
        { ...item, taxId },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  const removeTag = async (taxId: string, item: ITagRequest): Promise<void> => {
    try {
      await api.put(
        '/tags/remove',
        { ...item, taxId },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  return {
    getTags,
    addTag,
    removeTag,
  };
};

export default useTags;

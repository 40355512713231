import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CardContainer from '../../../components/forms/CardContainer';
import { IBasicUser } from '../../../interfaces/profile.interface';
import { AccessTime, AccountCircle, Key, SaveAs } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import { ClassicButton, ClassicSelect } from '../../../layouts/styled/buttons';
import { useEnterprise } from '../../../providers/EnterpriseProvider';
import PermissionGuard from '../../../guards/PermissionGuard';
import { ROUTE } from '../../../utils/route';
import { PermissionsDialog } from '../../../components/dialogs/PermissionsDialog';
import { useOperation } from '../../../providers/OperationProvider';
import i18next from 'i18next';
import usePermissions from '../../../hooks/usePermissions';
import { COLORS, OPERATIONS } from '../../../utils/constants';
import PhoneInput from 'react-phone-input-2';
import { validateEmail, validatePhone } from '../../../utils/validators';
import { SUPER_ADMIN_EMAIL } from '../../../constants/common';
import useProfile from '../../../hooks/useProfile';
import { FaRegTrashCan } from 'react-icons/fa6';

export default function UserAddPage() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { taxId } = useParams();
  const { profile } = state;
  const { setOperation } = useOperation();

  const navigate = useNavigate();
  const { getPermissionGroups } = usePermissions();
  const enterpriseProvider = useEnterprise();

  const [dataRefresh, setDataRefresh] = useState<number>(Date.now());
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isPermissionsDialog, setIsPermissionsDialogOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState<IBasicUser>({
    userHash: profile?.userHash ?? '',
    email: profile?.email ?? '',
    firstName: profile?.firstName ?? '',
    secondName: profile?.secondName ?? '',
    phone: profile?.phone ?? '',
    personalId: profile?.personalId ?? '',
    verificationLevel: profile?.verificationLevel ?? '',
  });
  const [permissionGroups, setPermissionGroups] = useState<string[]>([]);
  const [selectedPermissionGroup, setSelectedPermissionGroup] =
    useState<string>('');
  const [currentPermissions, setCurrentPermissions] = useState<string[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const [phoneError, setPhoneError] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const [phoneTouched, setPhoneTouched] = useState<boolean>(false);
  const [emailTouched, setEmailTouched] = useState<boolean>(false);
  const profileProvider = useProfile();

  useEffect(() => {
    const fetchPermissionGroups = async () => {
      const groups = await getPermissionGroups();
      setPermissionGroups(groups.map((el) => el.name));
      setSelectedPermissionGroup(groups[0].name);
    };
    fetchPermissionGroups();
  }, []);

  useEffect(() => {
    if (profile == null) setEditMode(true);
    else fetchUserPermissions();
  }, [dataRefresh]);

  useEffect(() => {
    if (emailTouched) {
      if (formData.email.length === 0)
        setEmailError(t('common.forms.errors.field-required') as string);
      else if (!validateEmail(formData.email))
        setEmailError(t('common.forms.errors.invalid-email') as string);
      else {
        setEmailError(undefined);
      }
    }
    if (phoneTouched) {
      if (formData.phone.length === 0)
        setPhoneError(t('common.forms.errors.field-required') as string);
      else if (!validatePhone(formData.phone))
        setPhoneError(t('common.forms.errors.invalid-phone') as string);
      else {
        setPhoneError(undefined);
      }
    }
  }, [formData.email, formData.phone]);

  const fetchUserPermissions = async () => {
    try {
      const permsResponse =
        await enterpriseProvider.getEnterpriseUserPermissions(
          taxId ?? '',
          profile.userHash,
        );
      const flags = permsResponse.map((permission) => permission.flag);
      setCurrentPermissions(flags);
      setSelectedPermissions(flags);
    } catch (error) {
      console.error('Błąd podczas pobierania uprawnień użytkownika:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await enterpriseProvider.removeEnterpriseUser(
        taxId ?? '',
        profile.userHash,
        i18next.resolvedLanguage,
      );
      navigate(
        ROUTE.dashboardPage.enterprise.users.replace(':taxId', taxId ?? ''),
      );
      setDeleteDialogOpen(false);
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.user-removed'),
      });
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  const closePermissionsDialog = () => {
    setIsPermissionsDialogOpen(false);
  };

  const handleSave = async () => {
    try {
      if (profile) {
        await enterpriseProvider.modifyEnterpriseMember(
          taxId as string,
          formData as IBasicUser,
        );
      } else {
        await enterpriseProvider.importEnterpriseUser(
          taxId ?? '',
          [formData],
          selectedPermissionGroup,
          i18next.resolvedLanguage,
        );
      }
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.user-modified'),
      });
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      if (profile) {
        setEditMode(false);
      } else {
        navigate(
          ROUTE.dashboardPage.enterprise.users.replace(':taxId', taxId ?? ''),
        );
      }
    }
  };

  const handleFieldChange = (field: keyof IBasicUser, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handlePermissions = async (
    event: React.ChangeEvent<{}>,
    value: readonly { label: string; value: string }[],
  ) => {
    const selectedLabels = value.map((item) => item.label);
    setSelectedPermissions(selectedLabels);
  };

  const getInvitedByLabel = (item: IBasicUser) => {
    const { email, firstName, secondName } = item;
    const parts = [];
    if (email) parts.push(email);
    if (firstName) parts.push(firstName);
    if (secondName) parts.push(secondName);
    return parts.join(', ');
  };

  const renderUserModify = () => {
    return (
      <>
        <List>
          {/* Personal Information */}
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountCircle />
              </Avatar>
            </ListItemAvatar>
            {editMode ? (
              <TextField
                label={t('common.profile.firstName')}
                defaultValue={formData?.firstName || ''}
                onChange={(event) =>
                  handleFieldChange('firstName', event.target.value)
                }
                fullWidth
              />
            ) : (
              <ListItemText
                primary={t('common.profile.firstName')}
                secondary={formData?.firstName || t('common.error.no-data')}
              />
            )}
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountCircle />
              </Avatar>
            </ListItemAvatar>
            {editMode ? (
              <TextField
                label={t('common.profile.secondName')}
                defaultValue={formData?.secondName || ''}
                onChange={(event) =>
                  handleFieldChange('secondName', event.target.value)
                }
                fullWidth
              />
            ) : (
              <ListItemText
                primary={t('common.profile.secondName')}
                secondary={formData?.secondName || t('common.error.no-data')}
              />
            )}
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountCircle />
              </Avatar>
            </ListItemAvatar>
            {editMode ? (
              <TextField
                label={t('common.profile.personalId')}
                defaultValue={formData?.personalId || ''}
                onChange={(event) =>
                  handleFieldChange('personalId', event.target.value)
                }
                fullWidth
              />
            ) : (
              <ListItemText
                primary={t('common.profile.personalId')}
                secondary={formData?.personalId || t('common.error.no-data')}
              />
            )}
          </ListItem>

          {/* Contact Information */}
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountCircle />
              </Avatar>
            </ListItemAvatar>
            {editMode ? (
              <TextField
                label={t('common.profile.email')}
                defaultValue={formData?.email || ''}
                onChange={(event) =>
                  handleFieldChange('email', event.target.value)
                }
                fullWidth
                error={!!emailError}
                helperText={emailError}
                onFocus={() => setEmailTouched(true)}
                disabled={!!profile.email}
              />
            ) : (
              <ListItemText
                primary={t('common.profile.email')}
                secondary={formData?.email || t('common.error.no-data')}
              />
            )}
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountCircle />
              </Avatar>
            </ListItemAvatar>
            {editMode ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <PhoneInput
                    value={formData.phone}
                    onChange={(e) =>
                      handleFieldChange('phone', e.length > 0 ? '+' + e : e)
                    }
                    country={'pl'}
                    countryCodeEditable={false}
                    inputStyle={{
                      width: '100%',
                      color: COLORS.primaryColor,
                      borderColor: phoneError
                        ? COLORS.fieldErrorColor
                        : COLORS.greyColor,
                    }}
                    onFocus={() => setPhoneTouched(true)}
                    dropdownStyle={{ height: '300px' }}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      color: phoneError
                        ? COLORS.fieldErrorColor
                        : COLORS.accentColor,
                      marginLeft: '15px',
                      marginTop: '2.5px',
                      textAlign: 'left',
                    }}
                  >
                    {phoneError}
                  </Typography>
                </Box>
              </>
            ) : (
              <ListItemText
                primary={t('common.profile.phone')}
                secondary={
                  formData?.phone ? (
                    <>
                      {formData.phone}
                      {!formData.verificationLevel && (
                        <Chip
                          label={t('common.unverified')}
                          color="default"
                          variant="outlined"
                          size="small"
                          style={{ marginLeft: '8px' }}
                        />
                      )}
                    </>
                  ) : (
                    t('common.error.no-data')
                  )
                }
              />
            )}
          </ListItem>

          {/* Registration Details */}
          {profile.registeredBy && (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AccessTime />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t('common.profile.registered')}
                secondary={getInvitedByLabel(profile.registeredBy)}
              />
            </ListItem>
          )}
        </List>

        {profile && (
          <Box sx={{ padding: '5px 0' }}>
            {currentPermissions.map((label: string, index: number) => (
              <Chip key={index} label={label} />
            ))}
          </Box>
        )}
      </>
    );
  };

  const renderUserAdd = () => {
    return (
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AccountCircle />
            </Avatar>
          </ListItemAvatar>
          {editMode ? (
            <TextField
              label={t('common.profile.email')}
              defaultValue={formData?.email || ''}
              onChange={(event) =>
                handleFieldChange('email', event.target.value)
              }
              fullWidth
              error={!!emailError}
              helperText={emailError}
              onFocus={() => setEmailTouched(true)}
            />
          ) : (
            <ListItemText
              primary={t('common.profile.email')}
              secondary={formData?.email || t('common.error.no-data')}
            />
          )}
        </ListItem>

        {/* First name */}
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AccountCircle />
            </Avatar>
          </ListItemAvatar>
          {editMode ? (
            <TextField
              label={t('common.profile.firstName')}
              defaultValue={formData?.firstName || ''}
              onChange={(event) =>
                handleFieldChange('firstName', event.target.value)
              }
              fullWidth
            />
          ) : (
            <ListItemText
              primary={t('common.profile.firstName')}
              secondary={formData?.firstName || t('common.error.no-data')}
            />
          )}
        </ListItem>

        {/* Second name */}
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AccountCircle />
            </Avatar>
          </ListItemAvatar>
          {editMode ? (
            <TextField
              label={t('common.profile.secondName')}
              defaultValue={formData?.secondName || ''}
              onChange={(event) =>
                handleFieldChange('secondName', event.target.value)
              }
              fullWidth
            />
          ) : (
            <ListItemText
              primary={t('common.profile.secondName')}
              secondary={formData?.secondName || t('common.error.no-data')}
            />
          )}
        </ListItem>

        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AccountCircle />
            </Avatar>
          </ListItemAvatar>
          {editMode ? (
            <TextField
              label={t('common.profile.personalId')}
              defaultValue={formData?.personalId || ''}
              onChange={(event) =>
                handleFieldChange('personalId', event.target.value)
              }
              fullWidth
            />
          ) : (
            <ListItemText
              primary={t('common.profile.personalId')}
              secondary={formData?.personalId || t('common.error.no-data')}
            />
          )}
        </ListItem>

        {/* User phone */}
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AccountCircle />
            </Avatar>
          </ListItemAvatar>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            {editMode ? (
              <>
                <PhoneInput
                  onChange={(e) =>
                    handleFieldChange('phone', e.length > 0 ? '+' + e : e)
                  }
                  country={'pl'}
                  countryCodeEditable={false}
                  inputStyle={{
                    width: '100%',
                    color: COLORS.primaryColor,
                    borderColor: phoneError
                      ? COLORS.fieldErrorColor
                      : COLORS.accentColor,
                    borderRadius: '5px',
                  }}
                  onFocus={() => setPhoneTouched(true)}
                  dropdownStyle={{ height: '300px' }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    color: !!phoneError
                      ? COLORS.fieldErrorColor
                      : COLORS.accentColor,
                    marginLeft: '15px',
                    marginTop: '2.5px',
                    textAlign: 'left',
                  }}
                >
                  {phoneError}
                </Typography>
              </>
            ) : (
              <ListItemText
                primary={t('common.profile.phone')}
                secondary={formData?.phone || t('common.error.no-data')}
              />
            )}
          </div>
        </ListItem>

        {/* Permission group */}
        {permissionGroups && (
          <ClassicSelect
            labelId="question-label"
            id="question-select"
            fullWidth
            value={selectedPermissionGroup}
            onChange={(e) =>
              setSelectedPermissionGroup(e.target.value as string)
            }
          >
            {permissionGroups.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </ClassicSelect>
        )}
      </List>
    );
  };

  const handleSubmit = async () => {
    if (emailError || phoneError) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: t('common.forms.errors.invalid-phone-or-email'),
      });
      return;
    }
    setEditMode((prevEditMode) => !prevEditMode);
    if (editMode) {
      await handleSave();
    }
  };

  const isEnterpriseOwner = enterpriseProvider.enterprises.data.find((item) => {
    return (
      profile &&
      profile.userHash &&
      item.taxId === taxId &&
      item.ownerHash === profile.userHash
    );
  });

  return (
    <CardContainer
      title={t('pages.users.title') ?? ''}
      subheader={[
        !!profile &&
          ((!isEnterpriseOwner && profile.email != SUPER_ADMIN_EMAIL) ||
            profileProvider.currentProfile?.email === SUPER_ADMIN_EMAIL) && (
            <PermissionGuard
              permissions={['ENTERPRISE_ADD_PERMISSION']}
              taxIds={[taxId || '']}
            >
              <ClassicButton
                startIcon={<Key />}
                onClick={() => {
                  setIsPermissionsDialogOpen(true);
                }}
                disabled={editMode}
              >
                {t('common.button.set-permissions-btn')}
              </ClassicButton>
            </PermissionGuard>
          ),
        (profile === null ||
          (profile && !profile.email && !!isEnterpriseOwner) ||
          profileProvider.currentProfile?.email === SUPER_ADMIN_EMAIL) && (
          <ClassicButton
            startIcon={editMode ? <SaveAs /> : <EditIcon />}
            onClick={() => handleSubmit()}
          >
            {editMode
              ? t('common.button.save-btn')
              : t('common.button.edit-btn')}
          </ClassicButton>
        ),
        profile && !isEnterpriseOwner && profile.email != SUPER_ADMIN_EMAIL && (
          <PermissionGuard
            permissions={['ENTERPRISE_REMOVE_USER']}
            taxIds={[taxId || '']}
          >
            <ClassicButton
              startIcon={<RemoveIcon />}
              onClick={() => setDeleteDialogOpen(true)}
              disabled={editMode}
            >
              {t('common.button.remove-btn')}
            </ClassicButton>
          </PermissionGuard>
        ),
        profile && !isEnterpriseOwner && profile.email != SUPER_ADMIN_EMAIL && (
          <PermissionGuard
            permissions={['SUPER_ENTERPRISE_DELETE_USERS']}
            taxIds={[taxId || '']}
          >
            <ClassicButton
              startIcon={<FaRegTrashCan />}
              onClick={() => setDeleteDialogOpen(true)}
              disabled={editMode}
              sx={{
                backgroundColor: COLORS.errorColor,
                onHover: { backgroundColor: COLORS.errorColor },
              }}
            >
              DELETE
            </ClassicButton>
          </PermissionGuard>
        ),
      ]}
    >
      {profile ? renderUserModify() : renderUserAdd()}

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>{t('common.confirm-delete')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('common.confirm-remove-user')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>
            {t('common.button.cancel-btn')}
          </Button>
          <Button onClick={handleDeleteConfirm}>
            {t('common.button.confirm-btn')}
          </Button>
        </DialogActions>
      </Dialog>

      <PermissionsDialog
        userHash={profile?.userHash}
        taxId={taxId}
        onClose={closePermissionsDialog}
        open={isPermissionsDialog}
        currentPermissions={currentPermissions}
        selectedPermissions={selectedPermissions}
        handlePermissions={handlePermissions}
        setDataRefresh={setDataRefresh}
      ></PermissionsDialog>
    </CardContainer>
  );
}

import { useState } from 'react';
import { IconButton, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useParams } from 'react-router-dom';
import CardContainer from '../../../components/forms/CardContainer';
import { useTranslation } from 'react-i18next';
import {
  ClassicButton,
  ClassicInput,
  OutlineButton,
} from '../../../layouts/styled/buttons';
import { ROUTE } from '../../../utils/route';
import PermissionGuard from '../../../guards/PermissionGuard';
import { useOperation } from '../../../providers/OperationProvider';
import useTags from '../../../hooks/useTags';
import { ITagRequest } from '../../../interfaces/tags.interface';
import { OPERATIONS } from '../../../utils/constants';

export default function TagAddPage() {
  const { t } = useTranslation();
  const { taxId } = useParams();
  const { setOperation } = useOperation();
  const { addTag } = useTags();

  const [formData, setFormData] = useState<ITagRequest>({
    tags: [],
  });
  const navigate = useNavigate();

  const handleSave = async () => {
    try {
      if (formData.tags.length > 0) {
        formData.tags = formData.tags.filter((tag) => tag !== '');
        await addTag(taxId ?? '', formData);
        navigate(
          ROUTE.dashboardPage.enterprise.tags.replace(':taxId', taxId ?? ''),
        );
        setOperation({
          severity: OPERATIONS.SUCCESS,
          message: t('pages.operations.messages.tags-modified'),
        });
      }
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      navigate(
        ROUTE.dashboardPage.enterprise.tags.replace(':taxId', taxId ?? ''),
      );
    }
  };

  const handleChange = (index: number, value: string) => {
    const newFlags = [...formData.tags];
    newFlags[index] = value;
    setFormData((prevData) => ({ ...prevData, tags: newFlags }));
  };

  const handleAdd = () => {
    setFormData((prevData) => ({
      ...prevData,
      tags: [...prevData.tags, ''],
    }));
  };

  const handleRemove = (index: number) => {
    const newTags = formData.tags.filter((_, i) => i !== index);
    setFormData((prevData) => ({ ...prevData, tags: newTags }));
  };

  return (
    <CardContainer
      title={t('common.button.add-tag-btn') as string}
      actions={[
        <div>
          <OutlineButton onClick={handleAdd}>
            {t('common.button.add-tag-btn')}
          </OutlineButton>
          <PermissionGuard
            permissions={['ENTERPRISE_ADD_TAGS']}
            taxIds={[taxId || '']}
          >
            <ClassicButton
              disabled={formData.tags.length == 0}
              onClick={handleSave}
            >
              {t('common.button.add-btn')}
            </ClassicButton>
          </PermissionGuard>
        </div>,
      ]}
    >
      {formData.tags.map((tag, index) => (
        <Grid container spacing={2} sx={{ py: 1 }} key={index}>
          <Grid item xs={10}>
            <ClassicInput
              key={index}
              label="Tag"
              value={tag}
              onChange={(e) => handleChange(index, e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            <IconButton onClick={() => handleRemove(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </CardContainer>
  );
}

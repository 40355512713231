import React, { useEffect, useState } from 'react';
import NodeContainer from '../../../layouts/containers/NodeContainer';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Box, Chip, Tooltip } from '@mui/material';
import NoData from '../../../components/utils/NoData';
import PermissionGuard from '../../../guards/PermissionGuard';
import AddIcon from '@mui/icons-material/Add';
import { ClassicButton, ClassicInput } from '../../../layouts/styled/buttons';
import LoadingController from '../../../components/utils/LoadingController';
import Pagination from '@mui/material/Pagination';
import { ITEMS_PER_PAGE } from '../../../utils/constants';
import { useEnterprise } from '../../../providers/EnterpriseProvider';
import { usePermission } from '../../../providers/PermissionProvider';
import { useOperation } from '../../../providers/OperationProvider';
import {
  IQuestion,
  IQuestionHistory,
  IQuestionResponse,
} from '../../../interfaces/questions.interface';
import useQuestions from '../../../hooks/useQuestions';
import DynamicTable, {
  Column,
} from '../../../components/builders/DynamicTable';
import moment from 'moment';
import QuestionDialog from './QuestionDialog';
import { OPERATIONS } from '../../../utils/constants';
import QuestionHistory from './QuestionHistory';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_SORTING,
  EMPTY_DATA,
  SEARCH_INPUT_DELAY,
} from '../../../constants/common';

export default function QuestionsPage() {
  const { t } = useTranslation();
  const { setOperation } = useOperation();
  const { getQuestions, getQuestionHistory } = useQuestions();

  const permissionsProvider = usePermission();
  const enterpriseProvider = useEnterprise();

  const [searchParams, setSearchParams] = useSearchParams();
  const [questions, setQuestions] = useState<IQuestionResponse>(EMPTY_DATA);
  const [questionHistory, setQuestionHistory] = useState<IQuestionHistory[]>(
    [],
  );
  const [selectedQuestion, setSelectedQuestion] = useState<IQuestion>();
  const [expandedQuestion, setExpandedQuestion] = useState<IQuestion>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isQuestionHistoryLoading, setIsQuestionHistoryLoading] =
    useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [sorting, setSorting] = useState<string>(DEFAULT_SORTING);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchTimeout, setSearchTimeout] = useState<number>();
  const [refresh, setRefresh] = useState<number>(0);

  const columns: Column<IQuestion>[] = [
    {
      label: '',
      accessor: 'questionHash',
      type: 'expand',
    },
    {
      label: t('common.tables.header.question'),
      accessor: 'question',
      type: 'question',
    },
    {
      label: t('common.tables.header.type'),
      accessor: 'type',
      type: 'type',
    },
    {
      label: t('common.tables.header.taxId'),
      type: 'taxId',
      accessor: (item: IQuestion) => {
        return item.enterprises.map((el, index) => (
          <Tooltip
            title={t(`common.access-level-${el.accessLevel}`)}
            placement="top"
          >
            <Chip key={index} label={el.name} sx={{ cursor: 'pointer' }} />
          </Tooltip>
        ));
      },
    },
    {
      label: t('common.tables.header.tags'),
      accessor: 'tags',
      type: 'tags',
    },
    {
      label: t('common.tables.header.modified-at'),
      accessor: 'createdAt',
      type: 'createdAt',
      formatter: (value: number) => (
        <span>{moment.unix(value).format('DD-MM-YYYY HH:mm:ss')}</span>
      ),
    },
  ];

  useEffect(() => {
    fetchQuestions(pageIndex, sorting, searchQuery);
  }, [refresh, permissionsProvider.permissions]);

  useEffect(() => {
    const sorting = searchParams.get('sorting');
    setSearchQuery(searchParams.get('searchQuery') || '');
    setPageIndex(Number(searchParams.get('pageIndex')) || DEFAULT_PAGE_INDEX);
    setSorting(
      sorting && sorting.split('_').length === 2 ? sorting : DEFAULT_SORTING,
    );
    setRefresh(Date.now());
  }, [searchParams]);

  useEffect(() => {
    if (expandedQuestion) fetchQuestionHistory(expandedQuestion.questionHash);
  }, [expandedQuestion]);

  const onSortClick = (column: string) => {
    setSearchParams({
      searchQuery,
      sorting: `${column}_${
        sorting.split('_')[0] === column && sorting.split('_')[1] === 'ASC'
          ? 'DESC'
          : 'ASC'
      }`,
    });
  };

  const onQuestionClick = (question: IQuestion) => {
    setSelectedQuestion(question);
    setIsDialogOpen(true);
  };

  const fetchQuestions = async (
    pageIndex: number,
    sorting: string,
    searchQuery: string,
  ) => {
    setIsLoading(true);
    try {
      const taxIds = enterpriseProvider.enterprises.data
        .filter((ent) =>
          permissionsProvider.permissions.some(
            (perm) =>
              perm.taxId === ent.taxId &&
              perm.flag === 'ENTERPRISE_GET_QUESTIONS',
          ),
        )
        .map((el) => el.taxId);
      if (taxIds.length) {
        const fetchedQuestions = await getQuestions(taxIds, {
          pageIndex: pageIndex - 1,
          sorting,
          searchQuery,
        });
        setQuestions(fetchedQuestions);
      }
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchQuestionHistory = async (questionHash: string) => {
    setIsQuestionHistoryLoading(true);
    try {
      setQuestionHistory(await getQuestionHistory(questionHash));
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsQuestionHistoryLoading(false);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    pageIndex: number,
  ) => {
    setSearchParams({
      pageIndex: `${pageIndex}`,
      sorting,
      searchQuery,
    });
  };

  const handleSearch = (searchQuery: string) => {
    clearTimeout(searchTimeout);
    setSearchQuery(searchQuery);
    const timeout = setTimeout(() => {
      setSearchParams({
        sorting,
        searchQuery,
      });
    }, SEARCH_INPUT_DELAY);
    setSearchTimeout(timeout);
  };

  return (
    <header className="App-container">
      <NodeContainer
        title={t('pages.questions.title')}
        cta={
          <ClassicInput
            fullWidth
            label={t('common.search-label.questions')}
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
        }
        subtitle={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <PermissionGuard permissions={['ENTERPRISE_CREATE_QUESTION']}>
                <Box sx={{ ml: '10px' }}>
                  <ClassicButton
                    sx={{ mt: 0 }}
                    startIcon={<AddIcon />}
                    onClick={() => setIsDialogOpen(true)}
                  >
                    {t('common.button.add-btn')}
                  </ClassicButton>
                </Box>
              </PermissionGuard>
            </Box>
          </Box>
        }
      >
        {isLoading ? (
          <LoadingController />
        ) : questions.total === 0 ? (
          <NoData />
        ) : (
          <>
            <DynamicTable
              title={''}
              data={questions.data}
              columns={columns}
              onHeaderClick={onSortClick}
              onItemClick={onQuestionClick}
              sort={{
                column: sorting.split('_')[0],
                direction: sorting.split('_')[1],
              }}
              onExpandClick={(item) =>
                setExpandedQuestion(
                  item.questionHash === expandedQuestion?.questionHash
                    ? undefined
                    : item,
                )
              }
              innerData={
                <>
                  {isQuestionHistoryLoading ? (
                    <LoadingController />
                  ) : (
                    questionHistory &&
                    expandedQuestion && (
                      <QuestionHistory
                        data={questionHistory}
                        question={expandedQuestion}
                        isLoading={isQuestionHistoryLoading}
                      />
                    )
                  )}
                </>
              }
              expandedRow={expandedQuestion?.questionHash}
            />
          </>
        )}

        {questions.total > 0 && (
          <Pagination
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            count={Math.ceil(questions.total / ITEMS_PER_PAGE)}
            page={pageIndex}
            onChange={handlePageChange}
            style={{ margin: '10px 0' }}
          />
        )}
      </NodeContainer>

      {isDialogOpen && (
        <QuestionDialog
          question={selectedQuestion}
          open={isDialogOpen}
          handleClose={() => {
            setIsDialogOpen(false);
            setSelectedQuestion(undefined);
            setRefresh(Date.now());
          }}
        />
      )}
    </header>
  );
}

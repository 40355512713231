import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Typography,
  Modal,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEnterprise } from '../../../../providers/EnterpriseProvider';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from '../../../../utils/route';
import PermissionGuard from '../../../../guards/PermissionGuard';
import {
  IPermission,
  usePermission,
} from '../../../../providers/PermissionProvider';
import { useOperation } from '../../../../providers/OperationProvider';
import { COLORS, OPERATIONS } from '../../../../utils/constants';
import moment from 'moment';
import {
  SUPER_ENTERPRISE_EMAIL,
  SUPER_ENTERPRISE_PHONE,
  SUPER_ENTERPRISE_TAX_ID,
} from '../../../../constants/common';
import { useState } from 'react';
import Annotation from '../../../../components/Annotation';
import TagIcon from '@mui/icons-material/Tag';
import TableViewIcon from '@mui/icons-material/TableView';
import CloseIcon from '@mui/icons-material/Close';
import { compareArrays } from '../../../../utils/validators';
import { IPermissionGroup } from '../../../../interfaces/permissions.interface';
import { IEnterprise } from '../../../../interfaces/enterprise.interface';

interface Props {
  enterprise: IEnterprise;
  onEditClick: () => void;
  setRefresh: (value: number) => void;
  permissionGroups: IPermissionGroup[];
}

export default function EnterpriseItem({
  enterprise,
  onEditClick,
  setRefresh,
  permissionGroups,
}: Props) {
  const { t } = useTranslation();
  const permissionsProvider = usePermission();
  const enterpriseProvider = useEnterprise();
  const { setOperation } = useOperation();
  const navigate = useNavigate();
  const [openPermissionsModal, setOpenPermissionsModal] =
    useState<boolean>(false);
  const entPermissions = permissionsProvider.permissions.filter(
    (x) => x.taxId === enterprise.taxId,
  );
  const alignCenter = {
    display: 'flex',
    alignItems: 'center',
  };
  const handleActivateAccount = async (item: IEnterprise) => {
    try {
      await enterpriseProvider.activeEnterprise(item.taxId);
      setRefresh(Date.now());
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.enterprise-activated'),
      });
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  const handleDeactivateAccount = async (item: IEnterprise) => {
    try {
      await enterpriseProvider.deactivateEnterprise(item.taxId);
      setRefresh(Date.now());
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.enterprise-deactivated'),
      });
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  const handleClosePermissionsModal = () => {
    setOpenPermissionsModal(false);
  };

  const openPermissions = () => {
    setOpenPermissionsModal(true);
  };

  const getUserInEntepriseType = () => {
    let type = 'Custom';

    permissionGroups.forEach((group: IPermissionGroup) => {
      if (
        compareArrays(
          group.permissions,
          entPermissions.map((ent) => ent.flag),
        )
      ) {
        type = group.name;
      }
    });

    return type;
  };

  return (
    <>
      <Card sx={{ p: 2.5, alignSelf: 'stretch', width: '100%' }}>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
            width: '100%',
          }}
        >
          {enterprise.logoUrl && (
            <>
              <Grid item xs={4}>
                <img
                  src={enterprise.logoUrl}
                  alt="Logo"
                  style={{
                    width: '70px',
                    height: '70px',
                    objectFit: 'contain',
                    borderRadius: '8px',
                  }}
                />
              </Grid>
            </>
          )}
          <Grid
            item
            xs={!enterprise.logoUrl ? 12 : 8}
            sx={{ justifyContent: 'left', ...alignCenter }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 600, color: '#333', lineHeight: 1.2 }}
            >
              {enterprise.name}
            </Typography>
          </Grid>
        </Grid>

        <Grid container p={0} sx={{ mb: 2 }}>
          <Grid container>
            <Grid item xs={4} sx={{ paddingRight: '4px' }}>
              <Typography variant="body2" align="left">
                {t(`common.profile.taxId`)}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle1" align="left">
                {enterprise.taxId}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            {(enterprise.street ||
              enterprise.building ||
              enterprise.apartment ||
              enterprise.zip ||
              enterprise.city ||
              enterprise.country) && (
              <>
                <Grid item xs={4}>
                  <Typography variant="body2" align="left">
                    {t(`common.profile.address`)}
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography variant="subtitle1" align="left">
                    {[
                      enterprise.street,
                      enterprise.building,
                      enterprise.apartment,
                      enterprise.zip,
                    ]
                      .filter((el) => !!el && el !== '')
                      .join(', ')}
                    <br />
                    {[enterprise.city, enterprise.country]
                      .filter((el) => !!el && el !== '')
                      .join(', ')}
                  </Typography>
                </Grid>
              </>
            )}

            {enterprise.taxId === SUPER_ENTERPRISE_TAX_ID && (
              <>
                <Grid container>
                  <Grid item xs={4} sx={{ paddingRight: '4px' }}>
                    <Typography variant="body2" align="left">
                      {t(`common.profile.email`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="subtitle1" align="left">
                      {SUPER_ENTERPRISE_EMAIL}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} sx={{ paddingRight: '4px' }}>
                    <Typography variant="body2" align="left">
                      {t(`common.profile.phone`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="subtitle1" align="left">
                      {SUPER_ENTERPRISE_PHONE}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>

          {!!enterprise.membersCount && (
            <>
              <Grid container>
                <Grid item xs={4} sx={{ paddingRight: '4px' }}>
                  <Typography variant="body2" align="left">
                    {t(`common.profile.membersCount`)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="subtitle1" align="left">
                    {enterprise.membersCount}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          {!!enterprise.lastActivity && (
            <>
              <Grid container>
                <Grid item xs={4} sx={{ paddingRight: '4px' }}>
                  <Typography variant="body2" align="left">
                    {t(`common.profile.lastActivity`)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="subtitle1" align="left">
                    {moment
                      .unix(enterprise.lastActivity)
                      .format('DD-MM-YYYY HH:mm:ss')}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          {!!enterprise.createdAt && (
            <>
              <Grid container>
                <Grid item xs={4} sx={{ paddingRight: '4px' }}>
                  <Typography variant="body2" align="left">
                    {t(`common.profile.createdAt`)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="subtitle1" align="left">
                    {moment
                      .unix(enterprise.createdAt)
                      .format('DD-MM-YYYY HH:mm:ss')}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          <Grid container>
            <Grid item xs={4} sx={{ paddingRight: '4px', ...alignCenter }}>
              <Typography variant="body2" align="left">
                {t('common.forms.labels.user-permissions-groups')}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="subtitle1"
                align="left"
                sx={{ textTransform: 'capitalize;', ...alignCenter }}
              >
                {getUserInEntepriseType()}
              </Typography>
            </Grid>
          </Grid>

          {entPermissions && (
            <PermissionGuard
              permissions={['ENTERPRISE_SHOW_OWN_PERMISSIONS']}
              taxIds={[enterprise.taxId]}
            >
              <Grid
                container
                sx={{ mb: 2, cursor: 'pointer', textDecoration: 'underline' }}
                onClick={openPermissions}
              >
                <Grid item xs={4} sx={{ paddingRight: '4px', ...alignCenter }}>
                  <Typography variant="body2" align="left" sx={{}}>
                    {t('common.forms.labels.permissions')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sx={{ display: 'flex', justifyContent: 'left' }}
                >
                  <TableViewIcon />
                </Grid>
              </Grid>
            </PermissionGuard>
          )}
        </Grid>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: '1 1 auto',
            }}
          >
            <PermissionGuard
              permissions={['ENTERPRISE_ADD_TAGS']}
              taxIds={[enterprise.taxId]}
            >
              <Button
                sx={{ maxWidth: '64px', mb: '10px', mr: '10px', flex: '1' }}
                variant="outlined"
                color="primary"
                onClick={() =>
                  navigate(
                    ROUTE.dashboardPage.enterprise.tags.replace(
                      ':taxId',
                      enterprise.taxId ?? '',
                    ),
                    {
                      state: {
                        previousPath: ROUTE.dashboardPage.enterprise.root,
                        profile: null,
                      },
                    },
                  )
                }
              >
                <TagIcon />
              </Button>
            </PermissionGuard>
            <PermissionGuard
              permissions={['ENTERPRISE_GET_USERS']}
              taxIds={[enterprise.taxId]}
            >
              <Box sx={{ ...alignCenter, mb: '10px', flex: '1' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    navigate(
                      ROUTE.dashboardPage.enterprise.users.replace(
                        ':taxId',
                        enterprise.taxId ?? '',
                      ),
                      {
                        state: {
                          previousPath: ROUTE.dashboardPage.enterprise.root,
                          profile: null,
                        },
                      },
                    )
                  }
                >
                  {t('pages.users.title')}
                </Button>
                <Annotation
                  tooltip={`${t('common.annotations.enterprise-tags')}`}
                />
              </Box>
            </PermissionGuard>
          </Box>

          <PermissionGuard
            permissions={['ENTERPRISE_MODIFY_ENTERPRISE']}
            taxIds={[enterprise.taxId]}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={onEditClick}
              sx={{ mb: '10px' }}
            >
              {t('common.button.edit-btn')}
            </Button>
          </PermissionGuard>

          {enterprise.taxId !== SUPER_ENTERPRISE_TAX_ID && (
            <PermissionGuard
              permissions={[
                'SUPER_ENTERPRISE_DEACTIVATE_ENTERPRISE',
                'SUPER_ENTERPRISE_ACTIVATE_ENTERPRISE',
              ]}
            >
              <Button
                sx={{ mb: '0px' }}
                variant="contained"
                color={enterprise.activated ? 'secondary' : 'primary'}
                onClick={
                  enterprise.activated
                    ? () => handleDeactivateAccount(enterprise)
                    : () => handleActivateAccount(enterprise)
                }
              >
                {enterprise.activated
                  ? t('pages.enterprise.deactivateAccount')
                  : t('pages.enterprise.activateAccount')}
              </Button>
            </PermissionGuard>
          )}
        </Box>
      </Card>

      <Modal
        open={openPermissionsModal}
        onClose={handleClosePermissionsModal}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: COLORS.whiteColor,
            borderRadius: '8px',
            overflow: 'scroll',
            position: 'relative',
          }}
        >
          <Grid container sx={{ maxWidth: '80vw', maxHeight: '80vh', p: 3 }}>
            <Box
              sx={{
                position: 'absolute',
                top: '5px',
                right: '5px',
              }}
            >
              <CloseIcon
                sx={{
                  padding: '5px',
                  cursor: 'pointer',
                  width: '36px',
                  height: '36px',
                }}
                onClick={handleClosePermissionsModal}
              />
            </Box>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                gutterBottom
                p={1}
                sx={{ textAlign: 'center' }}
              >
                {t('common.forms.labels.permissions')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '0.5rem',
                  pb: '20px',
                }}
              >
                {entPermissions.map((perm: IPermission, index: number) => (
                  <Chip key={index} label={perm.flag} />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import NodeContainer from '../../../layouts/containers/NodeContainer';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import NoData from '../../../components/utils/NoData';
import { ROUTE } from '../../../utils/route';
import { ITEMS_PER_PAGE, OPERATIONS } from '../../../utils/constants';
import { useOperation } from '../../../providers/OperationProvider';
import LoadingController from '../../../components/utils/LoadingController';
import DynamicTable, {
  Column,
} from '../../../components/builders/DynamicTable';
import { ILog, ILogResponse } from '../../../interfaces/logs.interface';
import moment from 'moment';
import {
  SEARCH_INPUT_DELAY,
  DEFAULT_PAGE_INDEX,
  DEFAULT_SORTING,
  EMPTY_DATA,
} from '../../../constants/common';
import { ClassicInput } from '../../../layouts/styled/buttons';
import useNotifications from '../../../hooks/useNotifications';
import { Chip, Typography } from '@mui/material';
import { usePermission } from '../../../providers/PermissionProvider';
import { getBlockExplorer } from '../../../utils/getLink';

export default function NotificationPage() {
  const { t } = useTranslation();
  const { setOperation } = useOperation();
  const { getNotifications, updateNotificationsStatus } = useNotifications();
  const navigate = useNavigate();
  const permissionsProvider = usePermission();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [sorting, setSorting] = useState<string>(DEFAULT_SORTING);
  const [notifications, setNotifications] = useState<ILogResponse>(EMPTY_DATA);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchTimeout, setSearchTimeout] = useState<number>();
  const [refresh, setRefresh] = useState(0);

  const columnsBase: Column<ILog>[] = [
    {
      label: t('common.tables.header.operationAt'),
      accessor: 'createdAt',
      type: 'createdAt',
      formatter: (value: number) => (
        <span>{moment.unix(value).format('DD-MM-YYYY HH:mm:ss')}</span>
      ),
    },
    {
      label: t('common.tables.header.taxId'),
      accessor: 'taxId',
      type: 'taxId',
    },
    {
      label: t('common.tables.header.operationBy'),
      accessor: 'operationBy',
      type: 'operationBy',
    },
    {
      label: t('common.tables.header.operation'),
      accessor: 'operationType',
      type: 'operationType',
    },
    {
      label: t('common.tables.header.operationSubject'),
      accessor: 'operationSubject',
      type: 'operationSubject',
    },
    {
      label: t('common.tables.header.object'),
      accessor: 'object',
      type: 'object',
    },
    {
      label: t('common.tables.header.txnHash'),
      accessor: (item: ILog) => {
        return item && item.txnHash ? (
          <Typography variant={'body1'}>
            <a
              href={`${getBlockExplorer(item.createdAt)}${item.txnHash}`}
              target="_blank"
            >
              {item.txnHash.slice(0, 4)}...
              {item.txnHash.slice(-3)}
            </a>
          </Typography>
        ) : (
          <Chip
            label={t('common.no-data')}
            color="default"
            variant="outlined"
            size="small"
          />
        );
      },
      type: 'txnHash',
    },
  ];

  useEffect(() => {
    fetchNotifications(pageIndex, sorting, searchQuery);
  }, [refresh]);

  useEffect(() => {
    const sorting = searchParams.get('sorting');
    setSearchQuery(searchParams.get('searchQuery') || '');
    setPageIndex(Number(searchParams.get('pageIndex')) || DEFAULT_PAGE_INDEX);
    setSorting(
      sorting && sorting.split('_').length === 2 ? sorting : DEFAULT_SORTING,
    );
    setRefresh(Date.now());
  }, [searchParams]);

  const fetchNotifications = async (
    pageIndex: number,
    sorting: string,
    searchQuery: string,
  ) => {
    setLoading(true);
    try {
      const notifications = await getNotifications({
        pageIndex: pageIndex - 1,
        sorting,
        searchQuery,
      });
      setNotifications(notifications);

      if (notifications.data.length) {
        const notSeenNotifications = notifications.data.filter(
          (n) => !n.isSeen,
        );
        const hasNotSeenNotifications = notSeenNotifications?.length > 0;

        if (hasNotSeenNotifications) {
          updateNotificationsStatus(
            notSeenNotifications.map((item) => item.id),
          );
        }
      }
    } catch (error) {
      console.error('Error fetching logs:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setLoading(false);
    }
  };

  const onItemClick = (item: ILog) => {
    if (permissionsProvider.hasFlag('ENTERPRISE_GET_LOGS', [item.taxId])) {
      const operationType = item.operationType.toLowerCase();
      switch (true) {
        case operationType.includes('consent'):
          navigate(ROUTE.dashboardPage.consents.root);
          break;
        case operationType.includes('question'):
          navigate(ROUTE.dashboardPage.questions.root);
          break;
        case operationType.includes('user'):
          navigate(
            ROUTE.dashboardPage.enterprise.users.replace(':taxId', item.taxId),
          );
          break;
        case operationType.includes('enterprise'):
          navigate(ROUTE.dashboardPage.enterprise.root);
          break;
        case operationType.includes('benefit'):
          navigate(ROUTE.dashboardPage.benefits.root);
          break;
        case operationType.includes('tag'):
          navigate(
            ROUTE.dashboardPage.enterprise.tags.replace(':taxId', item.taxId),
          );
          break;
      }
    }
  };

  const onSortClick = (column: string) => {
    setSearchParams({
      searchQuery,
      sorting: `${column}_${
        sorting.split('_')[0] === column && sorting.split('_')[1] === 'ASC'
          ? 'DESC'
          : 'ASC'
      }`,
    });
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    pageIndex: number,
  ) => {
    setSearchParams({
      pageIndex: `${pageIndex}`,
      sorting,
      searchQuery,
    });
  };

  const handleSearch = (searchQuery: string) => {
    clearTimeout(searchTimeout);
    setSearchQuery(searchQuery);
    const timeout = setTimeout(() => {
      setSearchParams({
        sorting,
        searchQuery,
      });
    }, SEARCH_INPUT_DELAY);
    setSearchTimeout(timeout);
  };

  return (
    <header className="App-container">
      <NodeContainer
        title={t('pages.notification.title')}
        subtitle={t('pages.notification.subtitle')}
        cta={
          <ClassicInput
            fullWidth
            label={t('common.search-label.operations')}
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
        }
      >
        {isLoading ? (
          <LoadingController />
        ) : notifications.data.length === 0 ? (
          <NoData />
        ) : (
          <DynamicTable
            title={''}
            data={notifications.data}
            columns={columnsBase}
            sort={{
              column: sorting.split('_')[0],
              direction: sorting.split('_')[1],
            }}
            onHeaderClick={onSortClick}
            onItemClick={onItemClick}
            onExpandClick={() => {}}
          />
        )}

        {notifications.total > 0 && (
          <Pagination
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            count={Math.ceil(notifications.total / ITEMS_PER_PAGE)}
            page={pageIndex}
            onChange={handlePageChange}
            style={{ margin: '10px 0' }}
          />
        )}
      </NodeContainer>
    </header>
  );
}

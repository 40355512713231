import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClassicButton,
  ClassicInput,
  ClassicSelect,
  OutlineButton,
} from '../../../layouts/styled/buttons';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import LoadingController from '../../../components/utils/LoadingController';
import EditIcon from '@mui/icons-material/Edit';
import { useEnterprise } from '../../../providers/EnterpriseProvider';
import { usePermission } from '../../../providers/PermissionProvider';
import PermissionGuard from '../../../guards/PermissionGuard';
import { useOperation } from '../../../providers/OperationProvider';
import useTags from '../../../hooks/useTags';
import { COLORS, OPERATIONS } from '../../../utils/constants';
import {
  IConsent,
  IConsentAttachment,
  IOperationDetails,
} from '../../../interfaces/consents.interface';
import useConsents from '../../../hooks/useConsents';
import moment from 'moment';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ConsentPrint from './prints/ConsentPrint';
import PrintIcon from '@mui/icons-material/Print';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import StatusChip from '../../../components/chips/StatusChip';
import ConsentActivityTable from './ConsentActivityTable';
import {
  ALLOWED_ACCESS_LEVELS,
  FILE_SIZE_UPLOAD_LIMIT,
  FILE_TYPE_LIMIT,
  MOBILE_DEVICE_SIGNATURE,
  OWNER_ACCESS_LEVEL,
  RESTRICTED_ACCESS_LEVEL,
  SIGNATURE_TYPES,
  TRANSACTION_CHECK_TIMEOUT,
} from '../../../constants/common';
import { IBasicUser } from '../../../interfaces/profile.interface';
import CSVExportButton from '../../../components/builders/CSVExportButton';
import Annotation from '../../../components/Annotation';
import CloseIcon from '@mui/icons-material/Close';
import useFiles from '../../../hooks/useFiles';
import DeleteIcon from '@mui/icons-material/Delete';
import { CgFileDocument } from 'react-icons/cg';
import useUtils from '../../../hooks/useUtils';
import TransactionStatus from '../../../components/TransactionStatus';
import { IEnterprise } from '../../../interfaces/enterprise.interface';
import { ITag } from '../../../interfaces/tags.interface';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { ICollaborator } from '../../../interfaces/collaborator.interface';

const initialConsent: IConsent = {
  id: 0,
  enterprises: [],
  consentHash: '',
  title: '',
  description: '',
  draft: true,
  activated: false,
  tags: [],
  createdAt: 0,
  gradient: 'default',
  signatureType: MOBILE_DEVICE_SIGNATURE,
  informationObligation: '',
  content: '',
  version: 1,
  totalSigns: 0,
  activeSigns: 0,
  prevVersion: '',
  nextVersion: '',
  timestamp: 0,
  attachments: [],
};

const initialError = {
  title: '',
  description: '',
  content: '',
  signatureType: '',
};

const csvHeaders = [
  { label: 'Consent Hash', key: 'consentHash' },
  { label: 'User Hash', key: 'userHash' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phone' },
  { label: 'Personal Id', key: 'personalId' },
  { label: 'First Name', key: 'firstName' },
  { label: 'Second Name', key: 'secondName' },
  { label: 'Verification Level', key: 'verificationLevel' },
  { label: 'Consent Title', key: 'consentTitle' },
  { label: 'Status', key: 'status' },
  { label: 'Txn Hash', key: 'txnHash' },
  { label: 'Notes', key: 'notes' },
  { label: 'Timestamp', key: 'timestamp' },
];

type Props = {
  consent?: IConsent;
  open: boolean;
  handleClose: () => void;
};

export default function ConsentDialog({ consent, open, handleClose }: Props) {
  const { t } = useTranslation();
  const { setOperation } = useOperation();
  const { getTags } = useTags();
  const {
    createDraftConsent,
    deleteDraftConsent,
    activeConsent,
    deactivateConsent,
    modifyConsent,
    getConsentVersion,
    getConsentByHash,
    withdrawConsents,
    signConsent,
    getConsentActivity,
    getConsentHistory,
    getConsentSignature,
    shareConsent,
  } = useConsents();
  const { getTxnStatus } = useUtils();

  const permissionsProvider = usePermission();
  const enterpriseProvider = useEnterprise();
  const filesProvider = useFiles();
  const [operations, setOperations] = useState<IOperationDetails[]>([]);
  const [csvData, setCsvData] = useState<Record<string, string>[]>([]);
  const [isCsvDataLoading, setIsCsvDataLoading] = useState<boolean>(false);
  const [user, setUser] = useState<IBasicUser>();
  const [signature, setSignature] = useState<string>();
  const [isConsentActivityLoading, setIsConsentActivityLoading] =
    useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isTagsDialogOpen, setIsTagsDialogOpen] = useState<boolean>(false);
  const [tagsSearchQuery, setTagsSearchQuery] = useState('');
  const [tags, setTags] = useState<ITag[]>([]);
  const [originalTags, setOriginalTags] = useState<ITag[]>([]);
  const [originalAttachments, setOriginalAttachments] = useState<
    IConsentAttachment[]
  >([]);
  const [attachments, setAttachments] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSharingDialogOpen, setIsSharingDialogOpen] =
    useState<boolean>(false);
  const [txnHash, setTxnHash] = useState<string>();
  const [taxIdToShare, setTaxIdToShare] = useState<string>('');
  const [accessLevel, setAccessLevel] = useState<number>(
    RESTRICTED_ACCESS_LEVEL,
  );
  const [isSignatureLoading, setIsSignatureLoading] = useState(false);
  const [areConsentVersionsLoading, setAreConsentVersionsLoading] =
    useState(false);
  const [areTagsLoading, setAreTagsLoading] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [fileError, setFileError] = useState<string>('');
  const [editMode, setEditMode] = useState(false);
  const [consentVersions, setConsentVersions] = useState<IConsent[]>([]);
  const [formData, setFormData] = useState<IConsent>(initialConsent);
  const [availableEnterprises, setAvailableEnterprises] = useState<
    IEnterprise[]
  >([]);
  const [formErrors, setFormErrors] =
    useState<Record<string, string>>(initialError);
  const [activityRefresh, setActivityRefresh] = useState<number>(0);

  const handleFileChange = async (e: any) => {
    if (fileError) {
      setFileError('');
    }

    if (e.target.files[0].size > FILE_SIZE_UPLOAD_LIMIT) {
      setFileError(`${t('common.forms.errors.file-size')}!`);
      return;
    }
    if (!FILE_TYPE_LIMIT.includes(e.target.files[0].type)) {
      setFileError(`${t('common.forms.errors.file-type')}!`);
      return;
    }

    setIsFileUploading(true);
    try {
      const file = e.target.files[0];
      const uploadCache = await filesProvider.getUploadUrl(
        formData.consentHash,
        file.name,
      );
      await filesProvider.uploadToAWS(uploadCache.url, file);
      setFormData((prevData) => ({
        ...prevData,
        attachments: [
          ...(prevData.attachments || []),
          {
            name: uploadCache.fileName,
            displayName: file.name,
            size: file.size,
          },
        ],
      }));
    } catch (e) {
      console.error('Error uploading file:', e);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    } finally {
      setIsFileUploading(false);
    }
  };

  const handleRemoveFile = async (index: number) => {
    const newFiles = formData.attachments.filter((_, i) => i !== index);
    await filesProvider.deleteFile(
      formData.consentHash,
      formData.attachments[index].name,
    );
    setFormData((prevData) => ({
      ...prevData,
      attachments: newFiles,
    }));
  };

  const handleOpenAttachment = async (index: number) => {
    const file = formData.attachments[index];
    const downloadCache = await filesProvider.getReadUrl(
      formData.consentHash,
      file.name,
    );
    window.open(downloadCache, '_blank');
  };

  useEffect(() => {
    consent ? setFormData(consent) : setEditMode(true);
  }, [consent]);

  useEffect(() => {
    if (formData.consentHash) {
      if (txnHash) {
        checkTransaction(txnHash);
      } else {
        updateConsentData(formData.consentHash);
      }
    }
  }, [formData.consentHash, txnHash]);

  useEffect(() => {
    if (formData.consentHash && !formData.draft) {
      fetchConsentActivity(formData.consentHash);
    }
  }, [activityRefresh]);

  useEffect(() => {
    if (editMode) {
      const availableEnterprises = enterpriseProvider.enterprises.data.filter(
        (ent) =>
          permissionsProvider.permissions.some(
            (permission) =>
              permission.taxId === ent.taxId &&
              permission.flag === 'ENTERPRISE_CREATE_CONSENT_DRAFT',
          ),
      );
      setAvailableEnterprises(availableEnterprises);
      if (
        availableEnterprises.length > 0 &&
        formData.enterprises.length === 0
      ) {
        const { taxId, name } = availableEnterprises[0];
        handleInputChange('enterprises', [
          { taxId, name, accessLevel: OWNER_ACCESS_LEVEL },
        ]);
      }
      fetchTags(availableEnterprises.map((el) => el.taxId));
    }
  }, [editMode]);

  useEffect(() => {
    if (operations.length > 0) {
      if (
        operations[0].isSigned &&
        consent &&
        consent.signatureType === MOBILE_DEVICE_SIGNATURE
      )
        fetchConsentSignature(
          operations[0].consentHash,
          operations[0].timestamp,
        );
    }
  }, [operations]);

  const checkTransaction = async (txnHash: string) => {
    try {
      const status = await getTxnStatus(txnHash);
      status === 2
        ? setTimeout(() => {
            checkTransaction(txnHash);
          }, TRANSACTION_CHECK_TIMEOUT)
        : setTxnHash('');
    } catch (e) {
      console.error('Cannot check transaction', e);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    }
  };

  const updateConsentData = async (consentHash: string): Promise<void> => {
    try {
      const consent = await getConsentByHash(consentHash);
      if (consent) {
        if (!consent.draft) {
          fetchConsentVersions(
            consentHash,
            consent.enterprises
              .filter((el) => el.accessLevel === OWNER_ACCESS_LEVEL)
              .map((el) => el.taxId),
          );
          fetchConsentActivity(consentHash);
        }
        setFormData(consent);
        setOriginalAttachments(consent.attachments);
        setOriginalTags(consent.tags);
      }
    } catch (e) {
      console.error('Error fetching consent:', e);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    }
  };

  const fetchConsentSignature = async (
    consentHash: string,
    timestamp: number,
  ) => {
    try {
      setIsSignatureLoading(true);
      setSignature(
        await getConsentSignature(consentHash, timestamp.toString()),
      );
    } catch (e) {
      console.error('Error fetching signature:', e);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    } finally {
      setIsSignatureLoading(false);
    }
  };

  const fetchTags = async (taxIds: string[]) => {
    try {
      setAreTagsLoading(true);
      const tags = await getTags(taxIds);
      setTags(tags);
    } catch (error) {
      console.error('Error fetching tags:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setAreTagsLoading(false);
    }
  };

  const fetchConsentActivity = async (consentHash: string) => {
    try {
      setIsConsentActivityLoading(true);
      const consentActivity = await getConsentActivity(consentHash);
      setUser(consentActivity.user);
      setOperations(consentActivity.operations);
    } catch (error) {
      console.error('Error fetching:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setIsConsentActivityLoading(false);
    }
  };

  const fetchConsentVersions = async (
    consentHash: string,
    taxIds: string[],
  ) => {
    try {
      setAreConsentVersionsLoading(true);
      if (
        permissionsProvider.hasFlag('ENTERPRISE_GET_CONSENT_VERSIONS', taxIds)
      )
        setConsentVersions(await getConsentVersion(consentHash));
    } catch (error) {
      console.error('Error fetching consent versions:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setAreConsentVersionsLoading(false);
    }
  };

  const setValidationErrors = () => {
    const errors: Record<string, string> = {};
    errors.title =
      formData.title === '' ? t('common.forms.errors.field-required') : '';
    errors.description =
      formData.description === ''
        ? t('common.forms.errors.field-required')
        : '';
    errors.content =
      formData.content === '' ? t('common.forms.errors.field-required') : '';
    errors.signatureType =
      formData.signatureType === ''
        ? t('common.forms.errors.field-required')
        : '';
    setFormErrors(errors);
  };

  const isFormValid = () =>
    formData.title !== '' &&
    formData.description !== '' &&
    formData.content !== '' &&
    formData.signatureType !== '';

  const handleSubmit = async () => {
    if (!editMode) {
      setEditMode(true);
      return;
    }
    if (isFormValid()) {
      formData.consentHash ? await editConsent() : await createConsent();
      setEditMode(false);
    } else {
      setValidationErrors();
    }
  };

  const createConsent = async () => {
    setIsLoading(true);
    try {
      const consentHash = await createDraftConsent(formData);
      setFormData((prevData) => ({
        ...prevData,
        draft: true,
        consentHash,
      }));
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.consent-created'),
      });
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
      handleClose();
    } finally {
      setIsLoading(false);
    }
  };

  const editConsent = async () => {
    setIsLoading(true);
    try {
      if (
        (consent &&
          (formData.title !== consent.title ||
            formData.description !== consent.description ||
            formData.informationObligation !== consent.informationObligation ||
            formData.content !== consent.content ||
            formData.signatureType !== consent.signatureType ||
            !formData.attachments.every((attachment: IConsentAttachment) =>
              originalAttachments.includes(attachment),
            ) ||
            !originalAttachments.every((attachment: IConsentAttachment) =>
              formData.attachments.includes(attachment),
            ) ||
            !formData.tags.every((tag: ITag) => originalTags.includes(tag)) ||
            !originalTags.every((tag: ITag) => formData.tags.includes(tag)))) ||
        !consent
      ) {
        const { txnHash, hash } = await modifyConsent(formData);
        setTxnHash(txnHash || '');
        handleInputChange('consentHash', hash);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
      setTxnHash(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (
    field: string,
    value: string | ITag[] | ICollaborator[],
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const reorder = (
    list: ITag[],
    startIndex: number,
    endIndex: number,
  ): ITag[] => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      formData.tags,
      result.source.index,
      result.destination.index,
    );

    handleInputChange('tags', [...items]);
  };

  const handleDeleteTag = (index: number) => {
    const newTags = formData.tags.filter((el, i) => i !== index);
    handleInputChange('tags', newTags);
  };

  const handleDelete = async () => {
    try {
      await deleteDraftConsent(formData.consentHash);
      handleClose();
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.consent-deleted'),
      });
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  const handleActive = async () => {
    setIsLoading(true);
    let txnHash;
    try {
      if (formData.activated) {
        txnHash = await deactivateConsent(formData.consentHash);
        setFormData((prevData) => ({
          ...prevData,
          activated: false,
        }));
      } else {
        txnHash = await activeConsent(formData.consentHash);
        setFormData((prevData) => ({
          ...prevData,
          activated: true,
          draft: false,
        }));
      }
      setTxnHash(txnHash);
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
      setTxnHash(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  const onSignConsentClick = async () => {
    try {
      setIsLoading(true);
      const txnHash = await signConsent(formData.consentHash);
      setTxnHash(txnHash);
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
      setTxnHash(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  const onRevokeConsentClick = async () => {
    setIsDialogOpen(false);
    setIsLoading(true);
    try {
      const txnHash = await withdrawConsents([formData.consentHash]);
      setTxnHash(txnHash);
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
      setTxnHash(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  const onCsvClick = async () => {
    setIsCsvDataLoading(true);
    try {
      const consentHistory = await getConsentHistory(formData.consentHash);
      const csvData = consentHistory.map((el) => ({
        consentHash: formData.consentHash,
        ...el.user,
        invitedBy: '',
        consentTitle: formData.title,
        status: el.operations[0].isSigned ? 'signed' : 'withdrawn',
        txnHash: el.operations[0].txnHash,
        notes: el.operations[0].notes,
        timestamp: moment
          .unix(el.operations[0].timestamp)
          .format('DD-MM-YYYY HH:mm:ss'),
      }));
      setCsvData(csvData);
      if (csvData.length === 0)
        setOperation({
          severity: OPERATIONS.ERROR,
          message: t('common.no-data'),
        });
    } catch (e) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    } finally {
      setIsCsvDataLoading(false);
    }
  };

  const onConfirmClick = async (): Promise<void> => {
    try {
      await shareConsent(formData.consentHash, taxIdToShare, accessLevel);
      setIsSharingDialogOpen(false);
      setTaxIdToShare('');
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.consent-shared'),
      });
    } catch (e) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              maxWidth: '1100px',
            },
          },
        }}
      >
        {!isConsentActivityLoading && formData.consentHash.length > 0 && (
          <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
            <StatusChip
              status={
                operations.length > 0
                  ? operations[0].isSigned
                    ? { label: t('common.tables.content.signed'), status: 2 }
                    : {
                        label: t('common.tables.content.withdrawn'),
                        status: 1,
                      }
                  : { label: t('common.tables.content.notSigned'), status: 0 }
              }
            />
          </Box>
        )}
        <Box
          sx={{
            textAlign: 'center',
            p: 2.5,
            borderBottom: `1px solid ${COLORS.primaryColor}`,
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '15px',
              right: '5px',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant={'h5'}>
            {t('pages.consents.title-editor')}
          </Typography>
        </Box>
        <DialogContent>
          <Grid
            container
            sx={{
              minWidth: { xs: '300px', md: '500px' },
              justifyContent: 'center',
            }}
          >
            {isLoading || txnHash ? (
              <>
                {txnHash ? (
                  <TransactionStatus txnHash={txnHash} />
                ) : (
                  <LoadingController />
                )}
              </>
            ) : (
              <Grid container spacing={2} padding={2}>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={2}
                  textAlign="left"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.forms.labels.enterprise')} :
                    </Typography>
                    {editMode && (
                      <Annotation
                        tooltip={`${t(
                          'common.annotations.consent-enterprise',
                        )}`}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {editMode &&
                    !formData.createdAt &&
                    availableEnterprises.length > 0 ? (
                      <ClassicSelect
                        fullWidth
                        labelId="enterprise-label"
                        id="enterprise-select"
                        value={
                          formData.enterprises[0].taxId ||
                          availableEnterprises[0].taxId
                        }
                        onChange={(e) =>
                          handleInputChange('enterprises', [
                            {
                              taxId: e.target.value as string,
                              name: availableEnterprises.find(
                                (el) => el.taxId === e.target.value,
                              )!.name,
                              accessLevel: OWNER_ACCESS_LEVEL,
                            },
                          ])
                        }
                      >
                        {availableEnterprises.map(
                          (item: IEnterprise, index: number) => (
                            <MenuItem key={index} value={item.taxId}>
                              {item.name}
                            </MenuItem>
                          ),
                        )}
                      </ClassicSelect>
                    ) : (
                      formData.enterprises.map((el, index) => (
                        <Tooltip
                          title={t(`common.access-level-${el.accessLevel}`)}
                          placement="top"
                        >
                          <Chip
                            key={index}
                            label={el.name}
                            sx={{ cursor: 'pointer' }}
                          />
                        </Tooltip>
                      ))
                    )}
                  </Grid>
                  {!editMode && consentVersions.length > 0 && (
                    <>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        display="flex"
                        justifyContent="left"
                      >
                        <Typography variant={'h5'}>
                          {t('common.tables.header.version')} :
                        </Typography>
                        <Annotation
                          tooltip={`${t('common.annotations.consent-version')}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        {!areConsentVersionsLoading ? (
                          <ClassicSelect
                            sx={{ width: '90px', height: '40px' }}
                            fullWidth
                            labelId="enterprise-label"
                            id="enterprise-select"
                            value={formData.consentHash}
                            onChange={(e) =>
                              handleInputChange(
                                'consentHash',
                                e.target.value as string,
                              )
                            }
                            disabled={formData.draft}
                          >
                            {consentVersions.map(
                              (item: IConsent, index: number) => (
                                <MenuItem key={index} value={item.consentHash}>
                                  {`v.${item.version}`}
                                </MenuItem>
                              ),
                            )}
                          </ClassicSelect>
                        ) : (
                          <CircularProgress />
                        )}
                      </Grid>
                    </>
                  )}
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.forms.labels.name')} :{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {editMode ? (
                      <ClassicInput
                        required
                        label={t('common.forms.labels.name')}
                        fullWidth
                        value={formData.title}
                        onChange={(e) =>
                          handleInputChange('title', e.target.value)
                        }
                        error={formErrors.title !== ''}
                        helperText={formErrors.title}
                      />
                    ) : (
                      <Typography variant={'body1'}>
                        {formData.title}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.forms.labels.consent-description')} :{' '}
                    </Typography>
                    {editMode && (
                      <Annotation
                        tooltip={`${t(
                          'common.annotations.consent-description',
                        )}`}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {editMode ? (
                      <ClassicInput
                        required
                        label={t('common.forms.labels.consent-description')}
                        multiline
                        rows={5}
                        fullWidth
                        value={formData.description}
                        onChange={(e) =>
                          handleInputChange('description', e.target.value)
                        }
                        error={formErrors.description !== ''}
                        helperText={formErrors.description}
                      />
                    ) : formData.description ? (
                      <Typography
                        variant={'body1'}
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {formData.description}
                      </Typography>
                    ) : (
                      <Chip label={t('common.no-data')} />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.tables.header.content')} :{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {editMode ? (
                      <ClassicInput
                        required
                        label={t('common.tables.header.content')}
                        multiline
                        rows={9}
                        fullWidth
                        value={formData.content}
                        onChange={(e) =>
                          handleInputChange('content', e.target.value)
                        }
                        error={formErrors.content !== ''}
                        helperText={formErrors.content}
                      />
                    ) : formData.content ? (
                      <Typography
                        variant={'body1'}
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {formData.content}
                      </Typography>
                    ) : (
                      <Chip label={t('common.no-data')} />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.tables.header.informationObligation')}:
                    </Typography>
                    {editMode && (
                      <Annotation
                        tooltip={`${t(
                          'common.annotations.consent-information-obligation',
                        )}`}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {editMode ? (
                      <div>
                        <ClassicInput
                          label={t(
                            'common.tables.header.informationObligation',
                          )}
                          multiline
                          rows={9}
                          fullWidth
                          value={formData.informationObligation}
                          onChange={(e) =>
                            handleInputChange(
                              'informationObligation',
                              e.target.value,
                            )
                          }
                          error={false}
                          helperText={formErrors.informationObligation}
                        />
                      </div>
                    ) : formData.informationObligation ? (
                      <Typography
                        variant={'body1'}
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {formData.informationObligation}
                      </Typography>
                    ) : (
                      <Chip label={t('common.no-data')} />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.tables.header.attachments')}:
                    </Typography>
                    {editMode && (
                      <Annotation
                        tooltip={`${t(
                          'common.annotations.consent-attachments',
                        )}`}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {editMode ? (
                      <>
                        <div
                          style={{
                            maxWidth: '100%',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <input
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            id="fileInput"
                            accept={FILE_TYPE_LIMIT.join(', ')}
                          />
                          <label htmlFor="fileInput">
                            <Button
                              variant="outlined"
                              component="span"
                              disabled={isFileUploading}
                              sx={{ width: '100%' }}
                            >
                              {t('common.button.upload-btn')}
                            </Button>
                          </label>
                        </div>
                        {formData.attachments?.map((file, index) => (
                          <ListItem
                            key={index}
                            style={{
                              border: `1px ${COLORS.accentColor} solid`,
                              borderRadius: '10px',
                              display: 'flex',
                              alignItems: 'center',
                              padding: '5px',
                              marginTop: '5px',
                            }}
                          >
                            <CgFileDocument style={{ margin: '5px' }} />
                            <ListItemText
                              primary={file.displayName}
                              secondary={
                                file.size &&
                                `Size: ${(file.size / 1024 / 1024).toFixed(
                                  2,
                                )} MB`
                              }
                              sx={{ marginLeft: '5px' }}
                            />
                            <IconButton
                              onClick={() => handleRemoveFile(index)}
                              edge="end"
                              aria-label="delete"
                              sx={{
                                color: COLORS.errorColor,
                                marginRight: '5px',
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItem>
                        ))}

                        {isFileUploading && (
                          <ListItem
                            key={'loading'}
                            style={{
                              border: `1px ${COLORS.accentColor} solid`,
                              borderRadius: '10px',
                              display: 'flex',
                              alignItems: 'center',
                              padding: '5px',
                              marginTop: '5px',
                            }}
                          >
                            <CircularProgress
                              size={20}
                              style={{ marginLeft: '5px' }}
                            />
                          </ListItem>
                        )}
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                          }}
                        >
                          {fileError && (
                            <Typography
                              variant={'caption'}
                              sx={{ color: COLORS.errorColor }}
                            >
                              {fileError}
                            </Typography>
                          )}
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        {formData.attachments?.length > 0 ? (
                          formData.attachments?.map((file, index) => (
                            <Chip
                              key={index}
                              label={file.displayName}
                              onClick={() => handleOpenAttachment(index)}
                            />
                          ))
                        ) : (
                          <Chip label={t('common.no-data')} />
                        )}
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.tables.header.signatureType')} :{' '}
                    </Typography>
                    {editMode && (
                      <Annotation
                        tooltip={`${t(
                          'common.annotations.consent-signature-type',
                        )}`}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {editMode ? (
                      <ClassicSelect
                        fullWidth
                        labelId="enterprise-label"
                        id="enterprise-select"
                        value={formData.signatureType}
                        onChange={(e) =>
                          handleInputChange(
                            'signatureType',
                            e.target.value as string,
                          )
                        }
                      >
                        {SIGNATURE_TYPES.map((item: string, index: number) => (
                          <MenuItem key={index} value={item}>
                            {t(`common.forms.labels.${item}`)}
                          </MenuItem>
                        ))}
                      </ClassicSelect>
                    ) : (
                      <Box sx={{ padding: '5px 0' }}>
                        <Chip
                          key={0}
                          label={t(
                            `common.forms.labels.${formData.signatureType}`,
                          )}
                        />
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.forms.labels.tag')} :{' '}
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} md={8}>
                    {editMode ? (
                      <>
                        {areTagsLoading ? (
                          <CircularProgress />
                        ) : (
                          <Box
                            sx={{
                              padding: '5px 0',
                              overflowX: 'scroll',
                              border: `1px solid ${COLORS.accentColor}`,
                              borderRadius: '20px',
                              width: '100%',
                            }}
                          >
                            <Grid
                              container
                              item
                              xs={12}
                              sx={{
                                justifyContent: 'center',
                                flexDirection: 'column',
                              }}
                            >
                              <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppableTags">
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {formData.tags.map((item, index) => (
                                        <Draggable
                                          key={`${item.taxId}-${item.tag}`}
                                          draggableId={`tag-${item.taxId}-${item.tag}`}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              spacing={1}
                                              sx={{
                                                p: 1,
                                                alignItems: 'center',
                                              }}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <Grid
                                                item
                                                xs={1}
                                                className="handle"
                                              >
                                                <IconButton disabled>
                                                  <DragIndicatorOutlinedIcon />
                                                </IconButton>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={9}
                                                md={10}
                                                sx={{
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                }}
                                              >
                                                <Tooltip
                                                  title={item.name}
                                                  placement="top"
                                                >
                                                  <Chip label={item.tag} />
                                                </Tooltip>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={1}
                                                style={{ textAlign: 'right' }}
                                              >
                                                <IconButton
                                                  onClick={() =>
                                                    handleDeleteTag(index)
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </Grid>
                                            </Grid>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>

                              <IconButton
                                onClick={() => setIsTagsDialogOpen(true)}
                                sx={{ width: '3rem', margin: 'auto' }}
                              >
                                <ControlPointIcon
                                  sx={{ width: '2rem', height: '2rem' }}
                                />
                              </IconButton>
                            </Grid>
                          </Box>
                        )}
                      </>
                    ) : (
                      <Box sx={{ padding: '5px 0' }}>
                        {formData.tags.length ? (
                          formData.tags.map((tag: ITag, index: number) => (
                            <Tooltip title={tag.name} placement="top">
                              <Chip
                                key={index}
                                label={tag.tag}
                                sx={{ cursor: 'pointer' }}
                              />
                            </Tooltip>
                          ))
                        ) : (
                          <Chip label={t('common.no-data')} />
                        )}
                      </Box>
                    )}
                  </Grid>
                  {!editMode && !!formData.createdAt && (
                    <>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        display="flex"
                        justifyContent="left"
                      >
                        <Typography variant={'h5'}>
                          {t('common.tables.header.modified-at')}:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Box sx={{ padding: '5px 0' }}>
                          <Chip
                            key={0}
                            label={moment
                              .unix(formData.createdAt)
                              .format('DD-MM-YYYY HH:mm:ss')}
                          />
                        </Box>
                      </Grid>
                    </>
                  )}
                  {!editMode && !!formData.activeSigns && (
                    <>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        display="flex"
                        justifyContent="left"
                      >
                        <Typography variant={'h5'}>
                          {t('common.forms.labels.activeSigns')} :{' '}
                        </Typography>
                        <Annotation
                          tooltip={`${t('common.annotations.consent-signs')}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography variant={'body1'}>
                          {formData.activeSigns}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {isConsentActivityLoading ? (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      {!editMode &&
                        operations.length > 0 &&
                        user &&
                        consent && (
                          <>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              display="flex"
                              justifyContent="left"
                            >
                              <Typography variant={'h5'}>
                                {t('common.forms.labels.salt')} :{' '}
                              </Typography>
                              <Annotation
                                tooltip={`${t('common.annotations.salt')}`}
                              />
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <Typography variant={'body1'}>
                                {operations[0].salt}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              display="flex"
                              justifyContent="left"
                            >
                              <Typography variant={'h5'}>
                                {t('common.forms.labels.userSignature')} :{' '}
                              </Typography>
                              <Annotation
                                tooltip={`${t(
                                  'common.annotations.user-signature',
                                )}`}
                              />
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <Typography variant={'body1'}>
                                {operations[0].userSignature}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <ConsentActivityTable
                                data={operations}
                                user={user}
                                consent={consent}
                                setRefresh={setActivityRefresh}
                              />
                            </Grid>
                          </>
                        )}
                      {!editMode &&
                        formData.consentHash &&
                        !formData.draft &&
                        operations.length > 0 && (
                          <>
                            <Grid item xs={12}>
                              {formData.signatureType !==
                                MOBILE_DEVICE_SIGNATURE &&
                              !operations[0].isSigned &&
                              formData.activated ? (
                                <ClassicButton
                                  key="grant-revoke-button"
                                  onClick={onSignConsentClick}
                                  startIcon={
                                    isLoading || txnHash ? (
                                      <CircularProgress
                                        style={{
                                          color: '#FFF',
                                        }}
                                        size={20}
                                      />
                                    ) : null
                                  }
                                >
                                  {t('common.toggle.grant-consent')}
                                </ClassicButton>
                              ) : operations[0].isSigned ? (
                                <ClassicButton
                                  key="grant-revoke-button"
                                  onClick={() => setIsDialogOpen(true)}
                                  startIcon={
                                    isLoading || txnHash ? (
                                      <CircularProgress
                                        style={{
                                          color: '#FFF',
                                        }}
                                        size={20}
                                      />
                                    ) : null
                                  }
                                >
                                  {t('common.toggle.revoke-consent')}
                                </ClassicButton>
                              ) : (
                                <></>
                              )}
                            </Grid>
                          </>
                        )}
                    </>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        {!(isLoading || txnHash) && (
          <DialogActions
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: { xs: 'center', md: 'flex-end' },
              width: '100%',
              p: 2.5,
            }}
          >
            {editMode && (
              <PermissionGuard
                permissions={['ENTERPRISE_CREATE_CONSENT_DRAFT']}
                taxIds={formData.enterprises
                  .filter((el) => el.accessLevel === OWNER_ACCESS_LEVEL)
                  .map((el) => el.taxId)}
              >
                <ClassicButton
                  sx={{ maxWidth: '8rem' }}
                  startIcon={<EditIcon />}
                  onClick={() => handleSubmit()}
                  disabled={!isFormValid()}
                >
                  {t('common.button.save-btn')}
                </ClassicButton>
              </PermissionGuard>
            )}
            {!editMode && (
              <>
                <PermissionGuard
                  permissions={['ENTERPRISE_SHARE_CONSENT']}
                  taxIds={formData.enterprises
                    .filter((el) => el.accessLevel === OWNER_ACCESS_LEVEL)
                    .map((el) => el.taxId)}
                >
                  <ClassicButton
                    sx={{ maxWidth: '8rem' }}
                    onClick={() => setIsSharingDialogOpen(true)}
                  >
                    {t('common.button.share-btn')}
                  </ClassicButton>
                </PermissionGuard>
                <PDFDownloadLink
                  document={
                    <ConsentPrint
                      consent={formData}
                      user={user}
                      operations={operations}
                      signature={signature}
                      attachments={attachments}
                    />
                  }
                  fileName="consent.pdf"
                >
                  {() => (
                    <ClassicButton
                      sx={{ maxWidth: '8rem' }}
                      startIcon={<PrintIcon />}
                      disabled={isSignatureLoading || isConsentActivityLoading}
                    >
                      {t('common.button.print-btn')}
                    </ClassicButton>
                  )}
                </PDFDownloadLink>
                {!formData.draft ? (
                  <>
                    <PermissionGuard
                      permissions={['ENTERPRISE_EXPORT_CSV']}
                      taxIds={formData.enterprises.map((el) => el.taxId)}
                    >
                      <CSVExportButton
                        data={csvData}
                        headers={csvHeaders}
                        isLoading={isCsvDataLoading}
                        onClick={onCsvClick}
                        disabled={isCsvDataLoading || isConsentActivityLoading}
                        filename={`consent-${formData.consentHash}`}
                      />
                    </PermissionGuard>
                  </>
                ) : (
                  <PermissionGuard
                    permissions={['ENTERPRISE_REMOVE_CONSENT_DRAFT']}
                    taxIds={formData.enterprises
                      .filter((el) => el.accessLevel === OWNER_ACCESS_LEVEL)
                      .map((el) => el.taxId)}
                  >
                    <ClassicButton
                      sx={{ maxWidth: '8rem' }}
                      key="delete-draft-button"
                      onClick={handleDelete}
                    >
                      {t('common.button.remove-btn')}
                    </ClassicButton>
                  </PermissionGuard>
                )}
                {!(
                  consentVersions.length > 0 &&
                  formData.version !==
                    consentVersions[consentVersions.length - 1].version
                ) && (
                  <PermissionGuard
                    permissions={['ENTERPRISE_MODIFY_CONSENTS']}
                    taxIds={formData.enterprises
                      .filter((el) => el.accessLevel === OWNER_ACCESS_LEVEL)
                      .map((el) => el.taxId)}
                  >
                    <ClassicButton
                      sx={{ maxWidth: '8rem' }}
                      startIcon={<EditIcon />}
                      onClick={() => handleSubmit()}
                      disabled={!isFormValid()}
                    >
                      {editMode
                        ? t('common.button.save-btn')
                        : t('common.button.edit-btn')}
                    </ClassicButton>
                  </PermissionGuard>
                )}
                <PermissionGuard
                  permissions={[
                    'ENTERPRISE_ACTIVATE_CONSENT',
                    'ENTERPRISE_DEACTIVATE_CONSENT',
                  ]}
                  taxIds={formData.enterprises
                    .filter((el) => el.accessLevel === OWNER_ACCESS_LEVEL)
                    .map((el) => el.taxId)}
                >
                  <ClassicButton
                    sx={{ maxWidth: '8rem' }}
                    onClick={handleActive}
                  >
                    {formData.activated
                      ? t('common.toggle.deactivate')
                      : formData.draft
                      ? t('common.toggle.publish')
                      : t('common.toggle.activate')}
                  </ClassicButton>
                </PermissionGuard>
              </>
            )}
          </DialogActions>
        )}
        <ConfirmationDialog
          open={isDialogOpen}
          description={t('common.confirm-consent-revoke')}
          handleClose={() => {
            setIsDialogOpen(false);
          }}
          onConfirmClick={onRevokeConsentClick}
        />
      </Dialog>

      <Dialog
        open={isSharingDialogOpen}
        onClose={() => {
          setIsSharingDialogOpen(false);
          setTaxIdToShare('');
        }}
      >
        <DialogTitle>
          <Typography variant={'h5'}>{t('common.sharing-title')}</Typography>
        </DialogTitle>
        <DialogContent sx={{ minWidth: { xs: '15rem', md: '25rem' } }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '100%',
              mb: 3,
            }}
          >
            <ClassicInput
              fullWidth
              label={t('common.forms.labels.taxId')}
              value={taxIdToShare}
              onChange={(e) => setTaxIdToShare(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              mb: 3,
            }}
          >
            <ClassicSelect
              fullWidth
              defaultValue={RESTRICTED_ACCESS_LEVEL}
              value={accessLevel}
              onChange={(e) => setAccessLevel(Number(e.target.value))}
              sx={{ mt: 0 }}
            >
              {ALLOWED_ACCESS_LEVELS.map((item: number) => (
                <MenuItem key={item} value={item}>
                  {t(`common.access-level-${item}`)}
                </MenuItem>
              ))}
            </ClassicSelect>

            <Annotation
              tooltip={`${t('common.annotations.consent-access-level')}`}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: { xs: 'center', md: 'flex-end' },
            width: '100%',
          }}
        >
          <OutlineButton sx={{ maxWidth: '10rem' }} onClick={handleClose}>
            {t('common.button.cancel-btn')}
          </OutlineButton>

          <ClassicButton
            sx={{ maxWidth: '10rem' }}
            onClick={onConfirmClick}
            disabled={!taxIdToShare}
          >
            {t(`common.button.confirm-btn`)}
          </ClassicButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isTagsDialogOpen}
        onClose={() => setIsTagsDialogOpen(false)}
      >
        <DialogTitle>
          <Typography variant={'h5'}>{t('pages.tags.title')}</Typography>
        </DialogTitle>
        <DialogContent
          sx={{ minWidth: { xs: '20rem', md: '35rem', minHeight: '600px' } }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '100%',
              mb: 3,
            }}
          >
            <ClassicInput
              fullWidth
              label={t('common.search-label.questions')}
              value={tagsSearchQuery}
              onChange={(e) => setTagsSearchQuery(e.target.value)}
            />
          </Box>

          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {tags
              .filter(
                (el) =>
                  el.tag
                    .toLowerCase()
                    .includes(tagsSearchQuery.toLowerCase()) &&
                  !formData.tags.some(
                    (tag) => tag.tag === el.tag && tag.taxId === el.taxId,
                  ),
              )
              .map((el, index) => (
                <Box sx={{ display: 'flex', marginBottom: 1.5 }}>
                  <Tooltip title={el.name} placement="top">
                    <Chip
                      label={el.tag}
                      onClick={() => {
                        handleInputChange('tags', [...formData.tags, el]);
                        setIsTagsDialogOpen(false);
                      }}
                    />
                  </Tooltip>
                </Box>
              ))}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

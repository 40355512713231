import { useAuth } from '../providers/AuthProvider';
import api from '../api';
import {
  IQuestionBasicData,
  IQuestion,
  IQuestionResponse,
  IQuestionHistory,
} from '../interfaces/questions.interface';
import { IParams } from '../interfaces/request.interface';
import { DEFAULT_EMAIL_LANGUAGE } from '../constants/common';

interface QuestionsContextType {
  getQuestions: (
    taxIds: string[],
    params: IParams,
  ) => Promise<IQuestionResponse>;
  createQuestion: (question: IQuestionBasicData) => Promise<string>;
  getQuestion: (questionHash: string) => Promise<IQuestion>;
  removeAnswers: (questionsHashes: string[]) => Promise<void>;
  getOwnQuestions: (params: IParams) => Promise<IQuestionResponse>;
  getQuestionHistory: (questionHash: string) => Promise<IQuestionHistory[]>;
  modifyQuestion: (question: IQuestion) => Promise<void>;
  shareQuestion: (
    questionHash: string,
    taxIdToShare: string,
    accessLevel: number,
  ) => Promise<void>;
}

const useQuestions = (): QuestionsContextType => {
  const auth = useAuth();

  const getQuestions = async (
    taxIds: string[],
    params: IParams,
  ): Promise<IQuestionResponse> => {
    try {
      const response = await api.get<IQuestionResponse>(
        `/enterprise/questions`,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
          params: {
            ...params,
            taxIds,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const createQuestion = async (
    question: IQuestionBasicData,
  ): Promise<string> => {
    try {
      const response = await api.post(`/questions/create`, question, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
      return response.data.questionHash || '';
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getQuestion = async (questionHash: string): Promise<IQuestion> => {
    try {
      const response = await api.get<IQuestion>(
        `/question?questionHash=${questionHash}`,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getOwnQuestions = async (
    params: IParams,
  ): Promise<IQuestionResponse> => {
    try {
      const response = await api.get<IQuestionResponse>(`/questions/own`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        params,
      });
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const getQuestionHistory = async (
    questionHash: string,
  ): Promise<IQuestionHistory[]> => {
    try {
      const response = await api.get<IQuestionHistory[]>(
        `/enterprise/questions/history`,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
          params: { questionHash },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const modifyQuestion = async (question: IQuestion): Promise<void> => {
    try {
      await api.put('/questions/modify/tags', question, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const removeAnswers = async (questionHashes: string[]): Promise<void> => {
    try {
      await api.put(
        `/questions/answers/remove`,
        { questionHashes },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const shareQuestion = async (
    questionHash: string,
    taxIdToShare: string,
    accessLevel: number,
    language: string = DEFAULT_EMAIL_LANGUAGE,
  ): Promise<void> => {
    try {
      await api.put(
        `/questions/share`,
        { questionHash, taxIdToShare, accessLevel, language },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        },
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  return {
    getQuestions,
    createQuestion,
    getQuestion,
    getQuestionHistory,
    modifyQuestion,
    removeAnswers,
    getOwnQuestions,
    shareQuestion,
  };
};

export default useQuestions;

import React, { ReactNode } from 'react';
import { usePermission } from '../providers/PermissionProvider';

interface Props {
  permissions: string[];
  taxIds?: string[];
  children: ReactNode;
}

const PermissionGuard = ({ permissions, taxIds = [], children }: Props) => {
  const perms = usePermission();

  const checkPermission = (
    requiredPermissions: string[],
    taxIds: string[],
  ): boolean => {
    if (requiredPermissions.length === 0) {
      return false;
    }

    const uniqueFlags = [
      ...perms.permissions.map((permission) => ({
        flag: permission.flag,
        taxId: permission.taxId,
      })),
    ];
    for (const p of requiredPermissions) {
      if (taxIds.length) {
        if (
          !uniqueFlags.some(
            (flag) => flag.flag === p && taxIds.includes(flag.taxId),
          )
        )
          return false;
      } else {
        if (!uniqueFlags.some((flag) => flag.flag === p)) return false;
      }
    }

    return true;
  };

  const userHasPermission = checkPermission(permissions, taxIds);

  return userHasPermission ? <>{children}</> : null;
};

export default PermissionGuard;

import React, { useEffect, useState } from 'react';
import NodeContainer from '../../../layouts/containers/NodeContainer';
import { useTranslation } from 'react-i18next';
import { useEnterprise } from '../../../providers/EnterpriseProvider';
import { Box, CircularProgress, Grid } from '@mui/material';
import { IProfileResponse } from '../../../interfaces/profile.interface';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import NoData from '../../../components/utils/NoData';
import { ClassicButton, ClassicInput } from '../../../layouts/styled/buttons';
import AddIcon from '@mui/icons-material/Add';
import PermissionGuard from '../../../guards/PermissionGuard';
import SortButton from '../../../components/actions/SortButton';
import UserItem from './items/UserItem';
import { ROUTE } from '../../../utils/route';
import {
  ITEMS_PER_PAGE,
  SORTING,
  OPERATIONS,
  SORTING_OPTIONS,
} from '../../../utils/constants';
import { useOperation } from '../../../providers/OperationProvider';
import { ISorting } from '../../../interfaces/sorting.interface';
import {
  DEFAULT_PAGE_INDEX,
  EMPTY_DATA,
  SEARCH_INPUT_DELAY,
} from '../../../constants/common';

export default function UserPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setOperation } = useOperation();
  const { taxId } = useParams();
  const enterpriseProvider = useEnterprise();
  const [searchParams, setSearchParams] = useSearchParams();
  const [users, setUsers] = useState<IProfileResponse>(EMPTY_DATA);
  const [loading, setLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [sorting, setSorting] = useState<ISorting>(SORTING.SECOND_NAME_ASC);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchTimeout, setSearchTimeout] = useState<number>();
  const [refresh, setRefresh] = useState<number>(0);

  useEffect(() => {
    if (refresh && taxId) fetchData(taxId, pageIndex, sorting, searchQuery);
  }, [refresh]);

  useEffect(() => {
    setRefresh(Date.now());
  }, [taxId]);

  useEffect(() => {
    const sorting = searchParams.get('sorting');
    const tab = searchParams.get('tab');
    setSearchQuery(searchParams.get('searchQuery') || '');
    setPageIndex(Number(searchParams.get('pageIndex')) || DEFAULT_PAGE_INDEX);
    setSorting(
      SORTING_OPTIONS.find((el) => el.value === sorting) ||
        SORTING.SECOND_NAME_ASC,
    );
    setRefresh(Date.now());
  }, [searchParams]);

  const onSortClick = (sorting: ISorting) => {
    setSearchParams({
      searchQuery,
      sorting: sorting.value,
    });
  };

  const fetchData = async (
    taxId: string,
    pageIndex: number,
    sorting: ISorting,
    searchQuery: string,
  ) => {
    try {
      const users = await enterpriseProvider.getEnterpriseUsers(taxId, {
        pageIndex: pageIndex - 1,
        sorting: sorting.value,
        searchQuery,
      });
      setUsers(users);
    } catch (error) {
      console.error('Error:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    pageIndex: number,
  ) => {
    setSearchParams({
      pageIndex: `${pageIndex}`,
      sorting: sorting.value,
      searchQuery,
    });
  };

  const handleSearch = (searchQuery: string) => {
    clearTimeout(searchTimeout);
    setSearchQuery(searchQuery);
    const timeout = setTimeout(() => {
      setSearchParams({
        sorting: sorting.value,
        searchQuery,
      });
    }, SEARCH_INPUT_DELAY);
    setSearchTimeout(timeout);
  };

  return (
    <NodeContainer
      title={t('pages.users.title')}
      cta={
        <ClassicInput
          fullWidth
          label={t('common.search-label.users')}
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      }
      subtitle={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            flexWrap: { xs: 'wrap', md: 'nowrap' },
            justifyContent: { xs: 'center', md: 'flex-end' },
            flexDirection: { xs: 'column-reverse', md: 'row' },
          }}
        >
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <SortButton
              sortOptions={[
                SORTING.SECOND_NAME_ASC,
                SORTING.CREATED_AT_DESC,
                SORTING.CREATED_AT_ASC,
                SORTING.INVITED_USERS_COUNT_DESC,
                SORTING.ACTIVITY_DESC,
              ]}
              onSelectSort={onSortClick}
              selectedSort={sorting}
            />
            <PermissionGuard
              permissions={['ENTERPRISE_ADD_USERS']}
              taxIds={[taxId || '']}
            >
              <Box>
                <ClassicButton
                  sx={{ mt: 0 }}
                  startIcon={<AddIcon />}
                  onClick={() =>
                    navigate(
                      ROUTE.dashboardPage.enterprise.addUser.replace(
                        ':taxId',
                        taxId ?? '',
                      ),
                      {
                        state: {
                          previousPath:
                            ROUTE.dashboardPage.enterprise.users.replace(
                              ':taxId',
                              taxId ?? '',
                            ),
                          profile: null,
                        },
                      },
                    )
                  }
                >
                  {t('common.button.add-btn')}
                </ClassicButton>
              </Box>
            </PermissionGuard>
          </Box>
        </Box>
      }
    >
      {loading ? (
        <CircularProgress />
      ) : users.total === 0 ? (
        <NoData />
      ) : (
        <Grid container spacing={2} sx={{ py: 5 }}>
          {users.data.map((item, index) => (
            <Grid key={item.id} item xs={12} sm={12} md={6}>
              <UserItem
                key={index}
                taxId={taxId ?? ''}
                profile={item}
                previousPath={ROUTE.dashboardPage.enterprise.users.replace(
                  ':taxId',
                  taxId ?? '',
                )}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {users.total > 0 && (
        <Pagination
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          count={Math.ceil(users.total / ITEMS_PER_PAGE)}
          page={pageIndex}
          onChange={handlePageChange}
          style={{ margin: '10px 0' }}
        />
      )}
    </NodeContainer>
  );
}

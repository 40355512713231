import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import {
  ClassicButton,
  ClassicInput,
  ClassicSelect,
  OutlineButton,
} from '../../../layouts/styled/buttons';
import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEnterprise } from '../../../providers/EnterpriseProvider';
import { usePermission } from '../../../providers/PermissionProvider';
import PermissionGuard from '../../../guards/PermissionGuard';
import { useOperation } from '../../../providers/OperationProvider';
import useTags from '../../../hooks/useTags';
import {
  IAnswerBasicData,
  IQuestion,
} from '../../../interfaces/questions.interface';
import useQuestions from '../../../hooks/useQuestions';
import { COLORS, OPERATIONS } from '../../../utils/constants';
import Annotation from '../../../components/Annotation';
import {
  ALLOWED_ACCESS_LEVELS,
  CUSTOM,
  DEFAULT_ANSWER,
  OPEN,
  OWNER_ACCESS_LEVEL,
  RESTRICTED_ACCESS_LEVEL,
  YES_NO,
} from '../../../constants/common';
import CloseIcon from '@mui/icons-material/Close';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { IEnterprise } from '../../../interfaces/enterprise.interface';
import { ITag } from '../../../interfaces/tags.interface';
import { ICollaborator } from '../../../interfaces/collaborator.interface';

const initialQuestion: IQuestion = {
  id: 0,
  questionHash: '',
  question: '',
  tags: [],
  type: YES_NO,
  answers: [DEFAULT_ANSWER],
  createdAt: 0,
  enterprises: [],
};

const initialError = {
  question: '',
  answers: '',
};

type Props = {
  question?: IQuestion;
  open: boolean;
  handleClose: () => void;
};

export default function QuestionDialog({ question, open, handleClose }: Props) {
  const { t } = useTranslation();
  const { setOperation } = useOperation();
  const { getTags } = useTags();
  const { createQuestion, modifyQuestion, getQuestion, shareQuestion } =
    useQuestions();
  const permissionsProvider = usePermission();
  const enterpriseProvider = useEnterprise();
  const [isTagsDialogOpen, setIsTagsDialogOpen] = useState<boolean>(false);
  const [tagsSearchQuery, setTagsSearchQuery] = useState('');
  const [tags, setTags] = useState<ITag[]>([]);
  const [originalTags, setOriginalTags] = useState<ITag[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSharingDialogOpen, setIsSharingDialogOpen] =
    useState<boolean>(false);
  const [taxIdToShare, setTaxIdToShare] = useState<string>('');
  const [accessLevel, setAccessLevel] = useState<number>(
    RESTRICTED_ACCESS_LEVEL,
  );
  const [areTagsLoading, setAreTagsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState<IQuestion>(initialQuestion);
  const [availableEnterprises, setAvailableEnterprises] = useState<
    IEnterprise[]
  >([]);
  const [formErrors, setFormErrors] =
    useState<Record<string, string>>(initialError);

  useEffect(() => {
    question
      ? handleInputChange('questionHash', question.questionHash)
      : setEditMode(true);
  }, [question]);

  useEffect(() => {
    if (formData.questionHash) {
      updateQuestionData(formData.questionHash);
    }
  }, [formData.questionHash]);

  useEffect(() => {
    if (formData.type === CUSTOM && editMode) {
      handleInputChange('answers', [{ ...DEFAULT_ANSWER }]);
    }
  }, [formData.type]);

  useEffect(() => {
    if (editMode) {
      const availableEnterprises = enterpriseProvider.enterprises.data.filter(
        (ent) =>
          permissionsProvider.permissions.some(
            (permission) =>
              permission.taxId === ent.taxId &&
              permission.flag === 'ENTERPRISE_CREATE_QUESTION',
          ),
      );
      setAvailableEnterprises(availableEnterprises);
      if (
        availableEnterprises.length > 0 &&
        formData.enterprises.length === 0
      ) {
        const { taxId, name } = availableEnterprises[0];
        handleInputChange('enterprises', [
          { taxId, name, accessLevel: OWNER_ACCESS_LEVEL },
        ]);
      }
      fetchTags(availableEnterprises.map((el) => el.taxId));
    }
  }, [editMode]);

  const reorder = (
    list: IAnswerBasicData[],
    startIndex: number,
    endIndex: number,
  ): IAnswerBasicData[] => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      formData.answers,
      result.source.index,
      result.destination.index,
    );

    handleInputChange('answers', items);
  };

  const updateQuestionData = async (questionHash: string) => {
    setIsLoading(true);
    try {
      const question = await getQuestion(questionHash);
      if (question) {
        setFormData(question);
        setOriginalTags(question.tags);
      }
    } catch (e) {
      console.error('Error fetching question:', e);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTags = async (taxIds: string[]) => {
    try {
      setAreTagsLoading(true);
      const tags = await getTags(taxIds);
      setTags(tags);
      setAreTagsLoading(false);
    } catch (error) {
      console.error('Error fetching tags:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setAreTagsLoading(false);
    }
  };

  const setValidationErrors = () => {
    const errors: Record<string, string> = {};
    errors.question =
      formData.question === '' ? t('common.forms.errors.field-required') : '';
    errors.answers =
      formData.type === CUSTOM &&
      (formData.answers.length === 0 ||
        formData.answers.some((el) => el.answer === ''))
        ? t('common.forms.errors.field-required')
        : '';
    setFormErrors(errors);
  };

  const isFormValid = () =>
    formData.question !== '' &&
    !(
      formData.type === CUSTOM &&
      (formData.answers.length === 0 ||
        formData.answers.some((el) => el.answer === ''))
    );

  const handleSubmit = async () => {
    try {
      if (!editMode) {
        setEditMode(true);
        return;
      }
      if (isFormValid()) {
        setEditMode(false);
        formData.questionHash
          ? await editQuestionClick()
          : await createQuestionClick();
      } else {
        setValidationErrors();
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  const createQuestionClick = async () => {
    try {
      const questionHash = await createQuestion({
        ...formData,
        answers:
          formData.answers
            .filter((el) => el.answer !== '' && !!el.answer)
            .map((el, index) => ({ ...el, orderNumber: index })) || [],
      });
      handleInputChange('questionHash', questionHash);
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.question-added'),
      });
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  const editQuestionClick = async () => {
    try {
      if (
        !formData.tags.every((tag: ITag) => originalTags.includes(tag)) ||
        !originalTags.every((tag: ITag) => formData.tags.includes(tag))
      )
        await modifyQuestion(formData);
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.question-edited'),
      });
    } catch (error) {
      console.error('An error occurred:', error);
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    }
  };

  const handleInputChange = (
    field: string,
    value: string | IAnswerBasicData[] | ITag[] | ICollaborator[],
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleAnswerChange = (value: string, index: number) => {
    if (value === '') {
      handleDeleteAnswer(index);
    } else {
      const newAnswers = [...formData.answers];
      newAnswers[index].answer = value;
      handleInputChange('answers', newAnswers);
    }
  };

  const handleAddAnswer = () => {
    handleInputChange('answers', [
      ...formData.answers,
      { answer: '', id: formData.answers.length + 1 },
    ]);
  };

  const handleDeleteAnswer = (index: number) => {
    const newAnswers = formData.answers.filter((el, i) => i !== index);
    const reorderedAnswers = newAnswers.length
      ? newAnswers.map((answer, i) => ({
          ...answer,
          id: i + 1,
        }))
      : [{ ...DEFAULT_ANSWER }];
    handleInputChange('answers', reorderedAnswers);
  };

  const reorderTags = (
    list: ITag[],
    startIndex: number,
    endIndex: number,
  ): ITag[] => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onTagDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = reorderTags(
      formData.tags,
      result.source.index,
      result.destination.index,
    );
    handleInputChange('tags', [...items]);
  };

  const handleDeleteTag = (index: number) => {
    const newTags = formData.tags.filter((el, i) => i !== index);
    handleInputChange('tags', newTags);
  };

  const onConfirmClick = async (): Promise<void> => {
    try {
      await shareQuestion(formData.questionHash, taxIdToShare, accessLevel);
      setIsSharingDialogOpen(false);
      setTaxIdToShare('');
      setOperation({
        severity: OPERATIONS.SUCCESS,
        message: t('pages.operations.messages.question-shared'),
      });
    } catch (e) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: e.response.data.details,
      });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              maxWidth: '1100px',
            },
          },
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            p: 2.5,
            borderBottom: `1px solid ${COLORS.primaryColor}`,
          }}
        >
          <Typography variant={'h5'}>
            {t('pages.questions.title-editor')}
          </Typography>
          <Typography component="p" variant="body1">
            {t('pages.questions.subtitle')}
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '15px',
              right: '5px',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Grid
            container
            sx={{
              minWidth: { xs: '300px', md: '500px' },
              justifyContent: 'center',
            }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Grid container spacing={2} padding={2}>
                <Grid
                  container
                  spacing={2}
                  textAlign="left"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.forms.labels.enterprise')} :
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {editMode &&
                    !formData.createdAt &&
                    availableEnterprises.length > 0 ? (
                      <ClassicSelect
                        fullWidth
                        labelId="enterprise-label"
                        id="enterprise-select"
                        value={
                          formData.enterprises[0].taxId ||
                          availableEnterprises[0].taxId
                        }
                        onChange={(e) =>
                          handleInputChange('enterprises', [
                            {
                              taxId: e.target.value as string,
                              name: availableEnterprises.find(
                                (el) => el.taxId === e.target.value,
                              )!.name,
                              accessLevel: OWNER_ACCESS_LEVEL,
                            },
                          ])
                        }
                      >
                        {availableEnterprises.map(
                          (item: IEnterprise, index: number) => (
                            <MenuItem key={index} value={item.taxId}>
                              {item.name}
                            </MenuItem>
                          ),
                        )}
                      </ClassicSelect>
                    ) : (
                      formData.enterprises.map((el, index) => (
                        <Tooltip
                          title={t(`common.access-level-${el.accessLevel}`)}
                          placement="top"
                        >
                          <Chip
                            key={index}
                            label={el.name}
                            sx={{ cursor: 'pointer' }}
                          />
                        </Tooltip>
                      ))
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.forms.labels.tag')} :{' '}
                    </Typography>
                    {editMode && (
                      <Annotation
                        tooltip={`${t('common.annotations.question-tags')}`}
                      />
                    )}
                  </Grid>
                  <Grid container item xs={12} md={8}>
                    {editMode ? (
                      <>
                        {areTagsLoading ? (
                          <CircularProgress />
                        ) : (
                          <Box
                            sx={{
                              padding: '5px 0',
                              overflowX: 'scroll',
                              border: `1px solid ${COLORS.accentColor}`,
                              borderRadius: '20px',
                              width: '100%',
                            }}
                          >
                            <Grid
                              container
                              item
                              xs={12}
                              sx={{
                                justifyContent: 'center',
                                flexDirection: 'column',
                              }}
                            >
                              <DragDropContext onDragEnd={onTagDragEnd}>
                                <Droppable droppableId="droppableTags">
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {formData.tags.map((item, index) => (
                                        <Draggable
                                          key={`${item.taxId}-${item.tag}`}
                                          draggableId={`tag-${item.taxId}-${item.tag}`}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              spacing={1}
                                              sx={{
                                                p: 1,
                                                alignItems: 'center',
                                              }}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <Grid
                                                item
                                                xs={1}
                                                className="handle"
                                              >
                                                <IconButton disabled>
                                                  <DragIndicatorOutlinedIcon />
                                                </IconButton>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={9}
                                                md={10}
                                                sx={{
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                }}
                                              >
                                                <Tooltip
                                                  title={item.name}
                                                  placement="top"
                                                >
                                                  <Chip label={item.tag} />
                                                </Tooltip>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={1}
                                                style={{ textAlign: 'right' }}
                                              >
                                                <IconButton
                                                  onClick={() =>
                                                    handleDeleteTag(index)
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </Grid>
                                            </Grid>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>

                              <IconButton
                                onClick={() => setIsTagsDialogOpen(true)}
                                sx={{ width: '3rem', margin: 'auto' }}
                              >
                                <ControlPointIcon
                                  sx={{ width: '2rem', height: '2rem' }}
                                />
                              </IconButton>
                            </Grid>
                          </Box>
                        )}
                      </>
                    ) : (
                      <Box sx={{ padding: '5px 0' }}>
                        {formData.tags.length ? (
                          formData.tags.map((tag: ITag, index: number) => (
                            <Tooltip title={tag.name} placement="top">
                              <Chip
                                key={index}
                                label={tag.tag}
                                sx={{ cursor: 'pointer' }}
                              />
                            </Tooltip>
                          ))
                        ) : (
                          <Chip label={t('common.no-data')} />
                        )}
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.forms.labels.type')} :
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {editMode && !formData.questionHash ? (
                      <ClassicSelect
                        fullWidth
                        value={formData.type}
                        defaultValue={YES_NO}
                        onChange={(event) =>
                          handleInputChange(
                            'type',
                            event.target.value as string,
                          )
                        }
                      >
                        <MenuItem value={YES_NO}>
                          {t('common.forms.labels.yes_no-item')}
                        </MenuItem>
                        <MenuItem value={CUSTOM}>
                          {t('common.forms.labels.custom-item')}
                        </MenuItem>
                        <MenuItem value={OPEN}>
                          {t('common.forms.labels.open-item')}
                        </MenuItem>
                      </ClassicSelect>
                    ) : (
                      <Typography variant={'body1'}>
                        {formData.type === YES_NO
                          ? t('common.forms.labels.yes_no-item')
                          : formData.type === CUSTOM
                          ? t('common.forms.labels.custom-item')
                          : formData.type === OPEN
                          ? t('common.forms.labels.open-item')
                          : formData.type}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="left"
                  >
                    <Typography variant={'h5'}>
                      {t('common.forms.labels.question')} :
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {editMode && !formData.questionHash ? (
                      <ClassicInput
                        required
                        label={t('common.forms.labels.question')}
                        fullWidth
                        value={formData.question}
                        onChange={(e) =>
                          handleInputChange('question', e.target.value)
                        }
                        error={formErrors.question !== ''}
                        helperText={formErrors.question}
                      />
                    ) : (
                      <Typography variant={'body1'}>
                        {formData.question}
                      </Typography>
                    )}
                  </Grid>
                  {formData.type === CUSTOM && (
                    <>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        display="flex"
                        justifyContent="left"
                      >
                        <Typography variant={'h5'}>
                          {t('common.forms.labels.answers')} :
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        {editMode && !formData.questionHash ? (
                          <Box
                            sx={{
                              padding: '5px 0',
                              overflowX: 'scroll',
                              border: `1px solid ${COLORS.accentColor}`,
                              borderRadius: '20px',
                            }}
                          >
                            <Grid
                              container
                              item
                              xs={12}
                              sx={{
                                justifyContent: 'center',
                                flexDirection: 'column',
                              }}
                            >
                              <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {formData.answers.map((item, index) => (
                                        <Draggable
                                          key={item.id}
                                          draggableId={`answer-${item.id}`}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              spacing={1}
                                              sx={{
                                                p: 1,
                                                alignItems: 'center',
                                              }}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <Grid
                                                item
                                                xs={1}
                                                className="handle"
                                              >
                                                <IconButton disabled>
                                                  <DragIndicatorOutlinedIcon />
                                                </IconButton>
                                              </Grid>
                                              <Grid item xs={10}>
                                                <ClassicInput
                                                  label={t(
                                                    'common.forms.labels.answer',
                                                  )}
                                                  value={item.answer}
                                                  onChange={(e) =>
                                                    handleAnswerChange(
                                                      e.target.value,
                                                      index,
                                                    )
                                                  }
                                                  fullWidth
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                xs={1}
                                                style={{ textAlign: 'right' }}
                                              >
                                                <IconButton
                                                  onClick={() =>
                                                    handleDeleteAnswer(index)
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </Grid>
                                            </Grid>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>

                              <IconButton
                                onClick={handleAddAnswer}
                                sx={{ width: '3rem', margin: 'auto' }}
                              >
                                <ControlPointIcon
                                  sx={{ width: '2rem', height: '2rem' }}
                                />
                              </IconButton>
                            </Grid>
                          </Box>
                        ) : (
                          <Box sx={{ padding: '5px 0' }}>
                            {formData.answers.map((answer, index) => (
                              <Chip key={index} label={answer.answer} />
                            ))}
                          </Box>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: { xs: 'center', md: 'flex-end' },
            width: '100%',
            p: 2.5,
          }}
        >
          {!editMode && (
            <PermissionGuard
              permissions={['ENTERPRISE_SHARE_CONSENT']}
              taxIds={formData.enterprises
                .filter((el) => el.accessLevel === OWNER_ACCESS_LEVEL)
                .map((el) => el.taxId)}
            >
              <ClassicButton
                sx={{ maxWidth: '8rem' }}
                onClick={() => setIsSharingDialogOpen(true)}
              >
                {t('common.button.share-btn')}
              </ClassicButton>
            </PermissionGuard>
          )}
          <PermissionGuard
            permissions={['ENTERPRISE_CREATE_QUESTION']}
            taxIds={formData.enterprises
              .filter((el) => el.accessLevel === OWNER_ACCESS_LEVEL)
              .map((el) => el.taxId)}
          >
            <ClassicButton
              sx={{ maxWidth: '10rem' }}
              startIcon={<EditIcon />}
              onClick={handleSubmit}
              disabled={!isFormValid()}
            >
              {editMode
                ? t('common.button.save-btn')
                : t('common.button.edit-btn')}
            </ClassicButton>
          </PermissionGuard>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isSharingDialogOpen}
        onClose={() => {
          setIsSharingDialogOpen(false);
          setTaxIdToShare('');
        }}
      >
        <DialogTitle>
          <Typography variant={'h5'}>{t('common.sharing-title')}</Typography>
        </DialogTitle>
        <DialogContent sx={{ minWidth: { xs: '15rem', md: '25rem' } }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '100%',
              mb: 3,
            }}
          >
            <ClassicInput
              fullWidth
              label={t('common.forms.labels.taxId')}
              value={taxIdToShare}
              onChange={(e) => setTaxIdToShare(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              mb: 3,
            }}
          >
            <ClassicSelect
              fullWidth
              defaultValue={RESTRICTED_ACCESS_LEVEL}
              value={accessLevel}
              onChange={(e) => setAccessLevel(Number(e.target.value))}
              sx={{ mt: 0 }}
            >
              {ALLOWED_ACCESS_LEVELS.map((item: number) => (
                <MenuItem key={item} value={item}>
                  {t(`common.access-level-${item}`)}
                </MenuItem>
              ))}
            </ClassicSelect>

            <Annotation
              tooltip={`${t('common.annotations.question-access-level')}`}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: { xs: 'center', md: 'flex-end' },
            width: '100%',
          }}
        >
          <OutlineButton sx={{ maxWidth: '10rem' }} onClick={handleClose}>
            {t('common.button.cancel-btn')}
          </OutlineButton>

          <ClassicButton
            sx={{ maxWidth: '10rem' }}
            onClick={onConfirmClick}
            disabled={!taxIdToShare}
          >
            {t(`common.button.confirm-btn`)}
          </ClassicButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isTagsDialogOpen}
        onClose={() => setIsTagsDialogOpen(false)}
      >
        <DialogTitle>
          <Typography variant={'h5'}>{t('pages.tags.title')}</Typography>
        </DialogTitle>
        <DialogContent
          sx={{ minWidth: { xs: '20rem', md: '35rem', minHeight: '600px' } }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '100%',
              mb: 3,
            }}
          >
            <ClassicInput
              fullWidth
              label={t('common.search-label.questions')}
              value={tagsSearchQuery}
              onChange={(e) => setTagsSearchQuery(e.target.value)}
            />
          </Box>

          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {tags
              .filter(
                (el) =>
                  el.tag
                    .toLowerCase()
                    .includes(tagsSearchQuery.toLowerCase()) &&
                  !formData.tags.some(
                    (tag) => tag.tag === el.tag && tag.taxId === el.taxId,
                  ),
              )
              .map((el, index) => (
                <Box sx={{ display: 'flex', marginBottom: 1.5 }}>
                  <Tooltip title={el.name} placement="top">
                    <Chip
                      label={el.tag}
                      onClick={() => {
                        handleInputChange('tags', [...formData.tags, el]);
                        setIsTagsDialogOpen(false);
                      }}
                    />
                  </Tooltip>
                </Box>
              ))}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

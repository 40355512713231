const PolishResource = {
  common: {
    welcome: 'Witaj!',
    here: 'tutaj',
    success: 'Udało się!',
    'public-beta': 'Publiczna Beta',
    motto: 'Pomóż nam budować zaufanie do nauki!',
    'go-to-dapp': 'Przejdż do dapp',
    'contact-support': 'Pomoc techniczna',
    unverified: 'Niezweryfikowany',
    'confirm-delete': 'Usuń',
    '2fa-header': 'Weryfikacja dwuetapowa',
    'enter-number': 'Proszę podać numer telefonu komórkowego do weryfikacji',
    'no-number':
      'Do Twojego konta nie jest podlączony numer telefonu. Bez niego niestety nie można korzystać z platformy. Aby zaktualizować numer telefonu, skontaktuj się z nami.',
    'enter-code': 'Proszę podać kod weryfikacyjny z wiadomości SMS',
    'confirm-number': 'Potwierdź numer na który będziemy wysyłać SMS',
    'confirm-delete-message': 'Czy na pewno chcesz usunać ten element?',
    'confirm-consents-revoke':
      'Czy jesteś pewien, że chcesz wycofać wszystkie zgody?',
    'confirm-consent-revoke': 'Czy jesteś pewien, że chcesz wycofać zgodę?',
    'confirm-consent-sign': 'Czy jesteś pewien, że chcesz udzielić zgodę?',
    'confirm-remove-project-user':
      'Czy jesteś pewien, że chcesz usunąć tego użytkownika z projektu?',
    'confirm-questions-revoke':
      'Czy jesteś pewien, że chcesz usunąć wszystkie odpowiedzi z pytań?',
    'confirm-question-revoke':
      'Czy jesteś pewien, że chcesz usunąć odpowiedź z pytania?',
    'confirm-remove-user':
      'Czy na pewno chcesz usunąć użytkownika z tej firmy?',
    'confirm-delete-profile-message':
      'Czy chcesz usunąć Twoje konto z aplikacji Data Lake?',
    'details-delete-profile message':
      'Pamiętaj, że Jeśli wyraziłeś zgody lub wypełniłeś odpowiedzi na pytania za pośrednictwem naszej platformy na rzecz spółki Data Lake lub naszych partnerów, są one w mocy aż do ich odwołania, nawet po usunięciu konta.',
    'deleted-profile-message': 'Twoje konto właśnie zostało usunięte',
    'no-data': 'Brak danych',
    'not-provided': 'Nie podano',
    'add-new-enterprise-btn': 'Dodaj konto firmowe',
    'wallet-declaration':
      'Jestem pełnoprawnym wlaścicielem portfelu napisanego poniżej i wyrażam zgodę na jego podlączenie do systemu Data Lake Application',
    'document-generation': 'Generowanie wydruku',
    'csv-generation': 'Generowanie CSV',
    'withdraw-reason': 'Powód wycofania',
    'sign-reason': 'Powód udzielenia',
    'sms-rate-limit': 'Wyślij kod ponownie za: ',
    'unsupported-device':
      'Twój ekran jest zbyt mały na złożenie podpisu. Obróć urządzenie o 90 stopni lub skorzystaj z innego urządzenia.',
    'sharing-title': 'Udostępnij dla...',
    settings: 'Ustawienia',
    optional: 'Opcjonalnie',
    actions: 'Akcje',
    points: 'Punkty',
    lake: '$LAKE',
    reason: 'Powód: ',
    'access-level-1': 'Ograniczony dostęp',
    'access-level-2': 'Pełny dostęp',
    'access-level-3': 'Uprawnienia właściciela',
    'required-answer': 'Wymagane Odpowiedzi',
    'required-consent': 'Czy zgoda jest wymagana?',
    'search-label': {
      projects: 'Szukaj według projektu lub danych użytkownika...',
      consents: 'Szukaj według zgody lub danych użytkownika...',
      questions: 'Szukaj według pytania lub danych użytkownika...',
      tags: 'Szukaj według tagu...',
      operations: 'Szukaj według subjektu, użytkownika lub rodzaju operacji...',
      enterprises: 'Szukaj według firmy...',
      users: 'Szukaj według danych użytkownika...',
    },
    'operation-status': {
      success: 'Operacja zakończona sukcesem!',
      info: 'Operacja w trakcie przetwarzania...',
      error: 'Operacja zakończona niepowodzeniem!',
    },
    'pending-transaction': {
      info: 'Jeszcze chwila, Twoje zgody są zapisywane',
      link: 'Zweryfikuj status Twojej operacji ',
    },
    annotations: {
      'consent-access-level':
        '"Ograniczony dostęp" umożliwia firmie: wykorzystanie tej zgody w swoich projektach, zbieranie podpisów i przeglądanie historii swoich użytkowników. "Pełny Dostęp" nadaje firmie dostęp do: listy wszystkich firm dzielących tę zgodę wraz z poziomami dostępu oraz historii wszystkich opercje wykonanych przez wszystkie firmy na tej zgodzie',
      'question-access-level':
        '"Ograniczony dostęp" umożliwia firmie: wykorzystanie tego pytania w swoich projektach, zbieranie odpowiedzi i przeglądanie historii swoich użytkowników. "Pełny Dostęp" nadaje firmie dostęp do: listy wszystkich firm dzielących to pytanie wraz z poziomami dostępu oraz historii wszystkich opercje wykonanych przez wszystkie firmy na tym pytaniu',
      'data-wont-be-saved': 'Twoje dane i zgody nie zostaną zapisane',
      'data-will-be-saved': 'Twoje dane i zgody zostaną zapisane',
      'connect-wallet':
        'Musisz mieć co najmniej 10.000 tokenów $LAKE w Twoim portfelu aby aktywować Twoją nową firmę',
      'enterprise-tags':
        'Skonfiguruj tagi aby filtrować i sortować obiekty w systemie',
      'question-description':
        'Opis pytania będzie widoczny zarówno dla Twojego personelu jak i osób udzielających zgody',
      'question-tags': 'Dodaj tagi aby przeszukiwać i filtrować Twoje pytania',
      'consent-enterprise':
        'Wybierz firmę, do której przyporządkowany będzie formularz zgody',
      'consent-description':
        'Opis zgody będzie widoczny zarówno dla Twojego personelu jak i osób udzielających zgodę',
      'consent-information-obligation':
        'W tym polu możesz wpisać dodatkowe informacje towarzyszące oświadczeniu woli widoczne dla osoby wyrażającej zgodę, na przykład obowiązek informacyjny',
      'consent-signature-type':
        'Wybierz typ podpisu, który chcesz zbierać przy tej zgodzie',
      'consent-version':
        'Każda zmiana której dokonasz w parametrach zgody będzie skutkować automatyczną zmianą wersji zgody',
      'consent-signs':
        'Całkowita liczba aktywnych zgód (podpisane i nie wycofane)',
      salt: 'Cztery losowo wygenerowane angielskie słowa',
      'user-signature':
        'KECCAK256 hash stworzony z danych użytkownika polączonych z solą przez przecinek',
      'project-enterprise':
        'Wybierz firmę,  do której przyporządkowany będzie formularz projektu',
      'project-description':
        'Opis projektu będzie widoczny zarówno dla Twojego personelu jak i osób udzielających zgody',
      'project-note':
        'Notatka nie będzie widoczna dla pacjentów / uczestników badań',
      'project-tags': 'Dodaj tagi aby przeszukiwać i filtrować Twoje projekty',
      'project-consents': 'Wybierz zgody dodane do formularza projektu',
      'project-question': 'Wybierz pytania dodane do formularza projektu',
      'project-contact':
        'Dane osoby kontaktowej będą widoczne zarówno dla Twojego personelu jak i osób udzielających zgody',
      'project-end-date':
        'Po tej dacie projekt zostanie automatycznie dezaktywowany',
      'project-required-fields':
        'Wybierz informacje, które będziesz zbierać od Twoich pacjentów / uczestników badania',
      'project-max-users': 'Wybierz Twój cel rekrutacyjny dla tego projektu',
      'user-flow': {
        online: {
          part1:
            'Jeśli podasz swój numer telefonu, utworzymy dla Ciebie konto w aplikacji Data Lake, abyś mógł zarządzać swoimi zgodami zgodnie z ',
          part2:
            ' za pomocą podanego numeru telefonu oraz maila, na którego otrzymałeś zaproszenie do projektu. Zasady przetwarzania Twoich danych osobowych znajdziesz w naszej',
          part3: ' Polityce Prywatności.',
        },
        visit:
          'Jeśli podasz adres mailowy oraz numer telefonu pacjenta, utworzymy dla pacjenta konto w aplikacji Data Lake, aby mógł zarządzać swoimi zgodami zgodnie z ',
        terms: 'Warunkami Korzystania z Usługi',
      },
      'access-key':
        'Jeśli wygenerujesz Klucz Dostępu do projektu, będziesz mógł korzystać z Widgetu Data Lake oraz generować linki publiczne.',
    },
    'question-answers': 'Odpowiedzi na pytania',
    'beta-access': {
      'success-title': 'Firma może być aktywowana!',
      'failed-title': 'Firma nie będzie automatycznie aktywowana',
      title: 'Uzyskaj dostęp do aplikacji',
      'success-message':
        'Masz wystarczającą ilość tokenów LAKE aby aktywować Twoją firmę!',
      'failed-message':
        'Nie wystarczającą ilość tokenów LAKE aby aktywować Twoją firmę!',
      message: 'Zakup tokeny dostępowe tutaj:',
      congratulations: 'Gratulujemy!',
      subtitle: 'Twoja firma została aktywowana!',
      disclaimer: 'Wersja Beta: Funkcjonalności mogą być ograniczone.',
      buy: 'Przejdż do dApp',
    },
    wallet: {
      connect: 'Podlącz portfel ERC20',
      verify: 'Zweryfikuj portfel',
      disconnect: 'Odlącz portfel',
    },
    status: {
      active: 'Aktywna',
      inactive: 'Nieaktywna',
    },
    button: {
      'activate-btn': 'Aktywuj',
      'share-btn': 'Udostępnij',
      'beta-test-btn': 'Przejdź do aplikacji',
      'deactivate-btn': 'Deaktywuj',
      'online-btn': 'Zaproś mailowo',
      'appointment-btn': 'Włącz pacjenta',
      'login-btn': 'Zaloguj się',
      'delete-profile-btn': 'Usuń profil',
      'confirm-btn': 'Potwierdź',
      'export-csv-btn': 'Eksportuj',
      'create-account-btn': 'Utwórz konto',
      'add-tag-btn': 'Dodaj tag',
      'add-question-btn': 'Dodaj pytanie',
      'add-answer-btn': 'Dodaj odpowiedź',
      'add-flag-btn': 'Dodaj flagę',
      'add-btn': 'Dodaj',
      'begin-btn': 'Rozpocznij!',
      'print-btn': 'Drukuj',
      'remove-btn': 'Usuń',
      'set-permissions-btn': 'Ustaw uprawnienia',
      'save-btn': 'Zapisz',
      'create-btn': 'Utwórz',
      'send-btn': 'Wyślij',
      'clear-filter-btn': 'Wyczyść filtr',
      'clear-sort-btn': 'Wyczyść filtr',
      'clear-signature-btn': 'Wyczyść',
      'next-btn': 'Dalej',
      'claim-btn': 'Odbierz',
      'show-more': 'Czytaj więcej',
      'show-less': 'Czytaj mniej',
      'cancel-btn': 'Anuluj',
      'sign-btn': 'Podpisz',
      'edit-btn': 'Edytuj',
      'end-btn': 'Zakończ',
      'sort-btn': 'Sortuj',
      'invite-btn': 'Zaproś',
      'filtr-btn': 'Filtruj',
      'more-info-btn': 'Więcej informacji',
      'show-answers-btn': 'Pokaż odpowiedzi',
      'start-btn': 'Rozpocznij',
      'upload-logo-btn': 'Dodaj logo przedsiębiorstwa',
      'send-code-btn': 'Wyślij kod',
      'verify-btn': 'Zweryfikuj',
      'revoke-all-btn': 'Wycofaj Wszystkie',
      'remove-answer-btn': 'Usuń Odpowiedź',
      'go-back-btn': 'Wróć do aplikacji',
      'back-btn': 'Wróć',
      'download-btn': 'Pobierz',
      'generate-btn': 'Wygeneruj',
      'chat-btn': 'Chat',
      'email-btn': 'Email',
      'upload-btn': 'Wgraj plik',
      'edit-phone': 'Zmień Numer',
      'copy-link-btn': 'Kopiuj Link',
      'close-btn': 'Zamknij',
    },
    tabs: {
      consents: 'ZGODY',
      questions: 'PYTANIA',
    },
    sorting: {
      all: 'WSZYSTKIE',
      signed: 'PODPISANE',
      drafts: 'SZKICE',
      oldest: 'NAJSTARSZE',
      newest: 'NAJNOWSZE',
      title: 'PO TYTULE',
      name: 'PO NAZWIE',
      popular: 'POPULARNE',
      potential: 'WIELKOŚĆ',
      ending: 'DATA KOŃCOWA',
      secondName: 'PO NAZWISKU',
      totalSigns: 'POPULARNE',
      activeSigns: 'AKTYWNE PODPISY',
      lastActivity: 'OSTATNIA AKTYWNOŚĆ',
      membersCount: 'CZŁONKOWIE',
      invitedUsersCount: 'NAJAKTYWNIEJSZE',
      tags: 'PO TAGACH',
    },
    notify: {
      'USER CREATED': 'UŻYTKOWNIK UTWORZONY',
      'USER UPDATED': 'UŻYTKOWNIK ZAKTUALIZOWANY',
      'ENTERPRISE CREATED': 'FIRMA UTWORZONA',
      'ENTERPRISE ACTIVATED': 'FIRMA AKTYWOWANA',
      'ENTERPRISE DEACTIVATED': 'FIRMA DEZAKTYWOWANA',
      'ENTERPRISE MODIFIED': 'FIRMA ZMODYFIKOWANA',
      'ENTERPRISE DELETED': 'FIRMA USUNIĘTA',
      'MAGIC LINK SENT': 'LINK WYSŁANY',
      'AUTHENTICATED WITH GOOGLE': 'UWIERZYTELNIONO PRZEZ GOOGLE',
      'SMS SENT': 'WIADOMOŚĆ SMS WYSŁANA',
      'AUTHENTICATED WITH SMS': 'UWIERZYTELNIONO PRZEZ SMS',
      AUTHENTICATED: 'UWIERZYTELNIONO',
      'CONSENT SIGNED': 'ZGODA PODPISANA',
      'CONSENT WITHDRAWN': 'ZGODA WYCOFANA',
      'MESSAGE FROM ENTERPRISE': 'WIADOMOŚĆ OD FIRMY',
      'PERMISSION ADDED': 'UPRAWNIENIA DODANE',
      'PERMISSION REMOVED': 'UPRAWNIENIA USUNIĘTE',
      'USER ADDED TO ENTERPRISE': 'UŻYTKOWNIK DODANY DO FIRMY',
      'USER REMOVED FROM ENTERPRISE': 'UŻYTKOWNIK USUNIĘTY Z FIRMY',
      'PERMISSION FLAGS ADDED': 'FLAGI UPRAWNIEŃ DODANE',
      'PERMISSION FLAGS DELETED': 'FLAGI UPRAWNIEŃ USUNIĘTE',
      'CONSENT DRAFT CREATED': 'SZKIC ZGODY UTWORZONY',
      'CONSENT DRAFT MODIFIED': 'SZKIC ZGODY ZMODYFIKOWANY',
      'CONSENT ACTIVATED': 'ZGODA AKTYWOWANA',
      'CONSENT DEACTIVATED': 'ZGODA DEZAKTYWOWANA',
      'CONSENT MODIFIED': 'ZGODA ZMODYFIKOWANA',
      'question DRAFT CREATED': 'SZKIC ANKIETY UTWORZONY',
      'question ACTIVATED': 'ANKIETA AKTYWOWANA',
      'question DEACTIVATED': 'ANKIETA DEZAKTYWOWANA',
      'question ANSWERED': 'ODPOWIEDZIANO W ANKIECIE',
      'question MODIFIED': 'ANKIETA ZMODYFIKOWANA',
      'TAGS ADDED': 'TAGI DODANE',
      'TAGS DELETED': 'TAGI USUNIĘTE',
      'TAGS ADDED TO CONSENT': 'TAGI DODANE DO ZGODY',
      'TAGS ADDED TO question': 'TAGI DODANE DO ANKIETY',
      'TAGS ADDED TO USER': 'TAGI DODANE DO UŻYTKOWNIKA',
      'TAGS DELETED FROM CONSENT': 'TAGI USUNIĘTE Z ZGODY',
      'TAGS DELETED FROM question': 'TAGI USUNIĘTE Z ANKIETY',
      'TAGS DELETED FROM USER': 'TAGI USUNIĘTE Z UŻYTKOWNIKA',
      'PROJECT CREATED': 'PROJEKT UTWORZONY',
      'QUESTION CREATED': 'PYTANIE UTWORZONE',
    },
    'terms-of-service': {
      part1: 'Logując się, zgadzasz się na ',
      part2: 'Warunki korzystania z usługi',
    },
    'data-processing-agreement': {
      part1: 'Akceptuję zapisy ',
      part2: 'Umowy Powierzenia Przetwarzania danych osobowych',
    },
    'privacy-policy': {
      'file-name': 'Polityka Prywatności',
    },
    toggle: {
      individual: 'Indywidualne',
      enterprise: 'Chcę zalogować się jako właściciel firmy',
      activate: 'Aktywuj',
      deactivate: 'Deaktywuj',
      publish: 'Publikuj',
      'revoke-consent': 'Wycofaj zgodę',
      'grant-consent': 'Udziel zgodę',
    },
    labels: {
      'users-in-project-table': 'Twoi użytkownicy w projekcie',
      'users-in-project-statistics-table':
        'Statystyka - Twoi użytkownicy w projekcie',
      'users-in-project-overview-table': 'Wszyscy użytkownicy w projekcie',
      'users-in-consent-table': 'Operacje na zgodzie',
      'users-in-question-table': 'Operacje na ankiecie',
      firstName: 'Imię',
      secondName: 'Nazwisko',
      email: 'E-mail',
      phone: 'Telefon',
      checkbox: 'Weryfikacja tożsamości przez personel',
      personalId: 'PESEL',
      operationsHistory: 'Historia operacji',
    },
    profile: {
      id: 'ID',
      userHash: 'User hash',
      ownerHash: 'Owner hash',
      name: 'Nazwa',
      taxId: 'Numer NIP',
      firstName: 'Imię',
      secondName: 'Nazwisko',
      email: 'Email',
      pesel: 'PESEL',
      phone: 'Telefon',
      'phone-email-statement':
        'Jeśli podasz email i numer telefonu pacjenta, konto zostanie utworzone w aplikacji Data Lake',
      country: 'Kraj',
      city: 'Miasto',
      street: 'Ulica',
      building: 'Budynek',
      apartment: 'Mieszkanie',
      source: 'Źródło',
      severity: 'Ważność',
      notes: 'Notatki',
      zip: 'Kod pocztowy',
      verificationLevel: 'Poziom weryfikacji',
      registered: 'Zarejestrowane przez',
      message: 'Treść',
      createdAt: 'Utworzono',
      activated: 'Aktywowane',
      deactivated: 'Dezaktywowane',
      personalId: 'PESEL',
      lastActivity: 'Ostatnia aktywność',
      membersCount: 'Ilość członków',
      wallet: 'Portfel Crypto',
      address: 'Adres',
    },
    tables: {
      header: {
        id: 'ID',
        email: 'E-mail',
        'first-name': 'Imię',
        'second-name': 'Nazwisko',
        'is-signed': 'Stan zgody',
        notes: 'Notatka',
        'created-at': 'Data utworzenia',
        'modified-at': 'Data modyfikacji',
        phone: 'Numer Telefonu',
        'invited-by': 'Zaproszony przez',
        'registred-by': 'Zarejestrowany przez',
        actions: 'Akcje',
        today: 'Dziś',
        yesterday: 'Wczoraj',
        thisWeek: 'W Tym Tygodniu',
        thisMonth: 'W Tym Miesiącu',
        lastMonth: 'W Zeszłym Miesiącu',
        all: 'Lącznie',
        title: 'Nazwa',
        description: 'Opis',
        taxId: 'NIP',
        tags: 'Tagi',
        draft: 'Szkic',
        activated: 'Aktywowany',
        version: 'Wersja',
        content: 'Treść',
        informationObligation: 'Dodatkowe informacje',
        attachments: 'Załączniki',
        signatureType: 'Forma podpisu',
        operationLog: 'Log Operacji',
        operation: 'Operacja',
        operationBy: 'Operacja Przez',
        salt: 'Sól',
        userSignature: 'Podpis Użytkownika',
        operationAt: 'Data Operacji',
        operationSubject: 'Kogo dotyczy',
        object: 'Obiekt operacji',
        txnHash: 'Hash Operacji',
        status: 'Status',
        accessKey: 'Klucz Dostępu',
        question: 'Pytanie',
        type: 'Typ',
      },
      content: {
        signed: 'Udzielona',
        withdrawn: 'Wycofana',
        notSigned: 'Nie podpisana',
      },
      project: {
        joined: 'Dołączony',
        notJoined: 'Nie dołączony',
      },
    },
    prints: {
      fullName: 'Imię i Nazwisko',
      personalId: 'PESEL',
      phone: 'Telefon',
      email: 'E-mail',
      question: 'Pytania',
      consents: 'Zgody',
      signed: 'wyrażona',
      notSigned: 'niewyrażona',
      withdrawn: 'wycofana',
      consentStatus: 'Status zgody: ',
      systemInfo: 'Zgody zostały wyrażone za pomocą systemu',
      operationInfo: 'Ostatnią operację możesz zweryfikować za pomocą tego',
      link: 'linku',
      version: 'Wersja',
      fromDay: 'z dnia',
      informationObligation: 'Dodatkowe informacje',
      attachments: 'Załączniki',
    },
    forms: {
      noEnterpriseAccount: 'Brak konta firmowego',
      createEnterpriseAccount: 'Załóż konto, aby rozpocząć',
      titles: {
        'enterprise-title': 'Tytuł strony',
        'consent-title': 'Twoja zgoda',
        'invite-participant-title': 'Zaproś uczestnika',
        'fill-participant-data': 'Wypełnij dane pacjenta',
        'user-data': 'Dane Użytkownika',
        project: 'Formularz projektu',
      },
      labels: {
        owner: 'Właściciel',
        taxId: 'NIP',
        title: 'Tytuł',
        pointsPrice: 'Cena punktowa',
        minPointsToClaim: 'Minimalne punkty',
        isCodeUnique: 'Unikalny kod',
        yes: 'Tak',
        no: 'Nie',
        allowedClaims: 'Dozowolone odbiory',
        codes: 'Kody',
        enterprise: 'Firma',
        requiredFields: 'Wymagane pola',
        'sub-account-statement':
          'Oświadczam, że zweryfikowałem tożsamość pacjenta.',
        answer: 'Odpowiedź',
        question: 'Pytanie',
        questions: 'Pytania',
        questionType: 'Typ pytania',
        company: 'Firma',
        'form.province': 'Województwo',
        'therapeutic-area': 'Specjalizacja',
        'account-type': 'Typ konta',
        'signature-type': 'Forma podpisu',
        createdAt: 'Data utworzenia',
        signs: 'Podpisy',
        activeSigns: 'Aktywne podpisy',
        salt: 'Sól',
        userSignature: 'Podpis Użytkownika',
        'mobile-device-signature': 'Urządzenie mobilne',
        'patient-declaration': 'Deklaracja pacjenta',
        'vat-number': 'Numer NIP',
        email: 'E-mail',
        name: 'Nazwa',
        tag: 'Twoje tagi',
        permissions: 'Uprawnienia',
        'user-permissions-groups': 'Uprawnienia użytkownika w grupie',
        'consent-content': 'Treść zgody',
        'information-obligation': 'Dodatkowe informacje',
        attachments: 'Załączniki',
        'consent-description': 'Opis zgody',
        city: 'Miasto',
        street: 'Ulica',
        building: 'Budynek',
        zip: 'Kod pocztowy',
        visibility: 'Widoczność',
        'invitation-link': 'Link zaproszeniowy',
        description: 'Opis',
        note: 'Notatka',
        consents: 'Zgody',
        contact: 'Osoba kontaktowa',
        'end-date': 'Koniec projektu',
        'max-patients': 'Liczba użytkowników',
        'yes_no-item': 'Tak / Nie',
        'custom-item': 'Dowolne',
        'open-item': 'Otwarte',
        active: 'Aktywne',
        inactive:
          'Nieaktyne - funkcjonalności konta przedsiębiorstwa nie dostępne',
        'my-last-activity': 'Moja ostatnia aktywaność',
        txn: 'Log Operacji',
        clear: 'Wyczyść',
        type: 'Typ pytania',
        answers: 'Odpowiedzi',
      },
      errors: {
        'field-required': 'Pole jest wymagane',
        'vat-number-too-short': 'Numer NIP jest zbyt krótki',
        'invalid-vat-number': 'Niepoprawny numer NIP',
        'gus-data-unavailable': 'Dane GUS są niedostępne',
        'at-least-one': 'Wymagana jest co najmniej jedna odpowiedź.',
        'invalid-email': 'Niepoprawny adres email',
        'invalid-phone': 'Niepoprawny numer telefonu',
        'invalid-phone-or-email': 'Niepoprawny numer telefonu lub adres email',
        'max-users': 'Wartość powinna być od 0 do 1000000000',
        'invalid-personal-id': 'Nieważny numer PESEL',
        'consents-required': 'Ta zgoda jest wymagana',
        'signature-required': 'Podpis jest wymagany',
        'file-size': 'Rozmiar pliku nie może przekraczać 2MB',
        'file-type':
          'Niepoprawny format pliku. Dozwolone formaty: jpg, jpeg, png, pdf',
        'project-question':
          'Podana odpowiedz nie spełnia warunków włączenia do projektu.',
      },
    },
    error: {
      required: 'Wymagane',
      'no-data': 'Brak danych do wyświetlenia',
      'consent-inactive': 'Zgoda jest nieaktywna',
      'consent-mobile': 'Zgoda wymaga podpisu w formie elektronicznej',
    },
  },
  userflow: {
    invite: {
      title: 'Invite a study participant',
    },
    welcome: {
      title: 'Witaj Bohaterze Danych!',
      description:
        'Dziękujemy, że chcesz podzielić się swoją historią leczenia i wynikami badań aby pomóc innym pacjentom. Pierwszy krok już za Tobą. Dopełnienie reszty formalności jest łatwe, a my przeprowadzimy Cię przez cały proces. Czy jesteś gotowy, aby zostać Bohaterem Danych?',
    },
    email: {
      title: 'Zacznijmy od e-maila!',
    },
    question: {
      title: 'Wypełnij pytania',
    },
    consent: {
      title: 'Zgody',
    },
    sign: {
      info: 'Proszę podpisać swoim pełnym imieniem i nazwiskiem',
      title: 'Podpisz',
    },
    congratulations: {
      title: 'Dziękujemy za wypełnienie formularza!',
      description:
        'Jeśli podałeś swój adres email oraz numer telefonu, kliknij poniżej aby zalogować się do systemu',
      contact:
        'W przypadku jakichkolwiek pytań dotyczących systemu Data Lake, skontaktuj się z nami!',
    },
  },
  pages: {
    signup: {
      title: 'Zarejestruj się',
      'welcome-disabled': 'Przepraszamy!',
      description:
        'Wpisz swój adres email i kliknij przycisk poniżej aby wejść do systemu. Po kliknięciu dostaniesz e-mail z linkiem który umożliwi zalogowanie.',
      'disabled-description':
        'Logowanie jest obecnie wyłączone, ponieważ system jest w trakcie aktualizacji. Proszę spróbować ponownie później.',
      'email-form': 'Twój e-mail',
      'success-msg':
        'Wkrótce powinieneś otrzymać od nas wiadomość e-mail. Jeśli go nie otrzymasz, sprawdź folder ze spamem, a następnie spróbuj zarejestrować się ponownie.',
      'error-msg': 'Niepoprawny adres e-mail',
      action: 'Zarejestruj się',
    },
    accountVerification: {
      title: 'Weryfikujemy Twoje zgłoszenie',
      subtitle: 'Poinformujemy Cię mailowo o statusie weryfikacji',
      questions: 'W przypadku pytań skontaktuj się z nami',
    },
    error404: {
      title: 'Ups!',
      description: 'Przepraszamy, wystąpił nieoczekiwany błąd.',
      'not-found': 'Nie znaleziono strony',
    },
    project: {
      title: 'Projekty',
      subtitle: '',
      'title-editor': 'Projekt',
    },
    userData: {
      title: 'Moje Dane',
      subtitle: '',
    },
    benefits: {
      title: 'Nagrody',
      subtitle: '',
      'title-editor': 'Nagroda',
    },
    consents: {
      title: 'Zgody',
      subtitle: '',
      'title-editor': 'Zgoda',
    },
    users: {
      title: 'Użytkownicy',
      subtitle: '',
    },
    dashboard: {
      title: 'Dashboard',
      subtitle: '',
    },
    operations: {
      title: 'Operacje',
      subtitle: '',
      messages: {
        'enrolled-to-project':
          'Użytkownik został pomyślnie zapisany do projektu',
        'invited-to-project':
          'Użytkownik został pomyślnie zaproszony do projektu',
        'project-edited': 'Projekt został pomyślnie zmodyfikowany',
        'project-saved': 'Projekt został pomyślnie zapisany',
        'question-revoked': 'Odpowiedź została pomyślnie usunięta',
        'consent-revoked': 'Zgoda została pomyślnie wycofana',
        'consent-signed': 'Zgoda została pomyślnie udzielona',
        'benefit-added': 'Nagroda została pomyślnie dodana',
        'question-edited': 'Pytanie zostało pomyślnie zmodyfikowane',
        'question-deleted': 'Pytanie zostało pomyślnie usunięte',
        'question-added': 'Pytania zostałt pomyślnie dodane',
        'question-shared': 'Pytanie zostało pomyślnie udostępnione',
        'project-activated': 'Projekt został pomyślnie aktywowany',
        'project-deactivated': 'Projekt został pomyślnie dezaktywowany',
        'project-liked': 'Projekt został pomyślnie dodany do ulubionych',
        'project-disliked': 'Projekt został pomyślnie usunięty z ulubionych',
        'enterprise-modified': 'Firma została pomyślnie zmodyfikowana',
        'tags-modified': 'Tagi zostały pomyślnie zmodyfikowane',
        'user-removed': 'Użytkownik został pomyślnie usunięty',
        'user-modified': 'Użytkownik został pomyślnie zmodyfikowany',
        'consent-created': 'Zgoda została pomyślnie utworzona',
        'consent-modified': 'Zgoda została pomyślnie zmodyfikowana',
        'consent-deleted': 'Zgoda została pomyślnie usunięta',
        'consent-activated': 'Zgoda została pomyślnie aktywowana',
        'consent-deactivated': 'Zgoda została pomyślnie dezaktywowana',
        'consent-shared': 'Zgoda została pomyślnie udostępniona',
        'enterprise-activated': 'Firma została pomyślnie aktywowana',
        'enterprise-deactivated': 'Firma została pomyślnie dezaktywowana',
        'benefit-activated': 'Nagroda została pomyślnie aktywowana',
        'benefit-deactivated': 'Nagroda została pomyślnie dezaktywowana',
        'permissions-modified': 'Uprawnienia zostały pomyślnie zmodyfikowane',
        'link-copied': 'Link został pomyślnie skopiowany',
      },
    },
    enterpriseOperations: {
      title: 'Operacje Firmy',
      subtitle: '',
    },
    notification: {
      title: 'Powiadomienia',
      'title-add': 'Dodaj powiadomienie',
      subtitle: '',
      'add-btn': 'Dodaj',
      form: {
        'user-id': 'ID Użytkownika',
        message: 'Twoja wiadomość...',
      },
    },
    questions: {
      title: 'Pytania',
      subtitle:
        'Skonfiguruj pytania, które możesz później wykorzystać w projekcie',
      'title-editor': 'Pytanie',
    },
    profile: {
      title: 'Profil',
      subtitle: '',
      header: 'Twój profil',
    },
    logout: {
      title: 'Logout',
    },
    enterprise: {
      title: 'Aktywuj konto',
      subtitle:
        'Musisz aktywować swoją firmę, aby dodać użytkowników z Twojej organizacji i skonfigurować ankiety, zgody i formularze projektów',
      header: 'Lista:',
      'create-account': 'Utwórz konto',
      activateAccount: 'Aktywuj konto',
      deactivateAccount: 'Dezaktywuj konto',
      editAccount: 'Edytuj konto',
      'title-editor': 'Firma',
    },
    termsService: {
      title: 'Polityka prywatności',
    },
    billing: {
      title: 'Cennik',
      subtitle: '',
    },
    enterprises: {
      title: 'Firmy',
      subtitle: 'Aktywne podmioty',
      managementUsers: 'Uzytkownicy',
    },
    tags: {
      title: 'Tagi',
      subtitle: 'Twoje oznaczenia',
    },
  },
  dialogs: {
    setPermissions: {
      title: 'Ustaw uprawnienia',
    },
  },
};

export default PolishResource;

import React, { useEffect, useState } from 'react';
import NodeContainer from '../../../layouts/containers/NodeContainer';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  Card,
  Box,
  Typography,
} from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import NoData from '../../../components/utils/NoData';
import AddIcon from '@mui/icons-material/Add';
import { ClassicButton, ClassicInput } from '../../../layouts/styled/buttons';
import { ROUTE } from '../../../utils/route';
import { OPERATIONS } from '../../../utils/constants';
import { useOperation } from '../../../providers/OperationProvider';
import { ITag } from '../../../interfaces/tags.interface';
import useTags from '../../../hooks/useTags';
import { SEARCH_INPUT_DELAY } from '../../../constants/common';

export default function TagPage() {
  const { t } = useTranslation();
  const { taxId } = useParams();
  const { setOperation } = useOperation();
  const { getTags, removeTag } = useTags();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [tags, setTags] = useState<ITag[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<ITag>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchTimeout, setSearchTimeout] = useState<number>();
  const [refresh, setRefresh] = useState<number>(0);

  useEffect(() => {
    const fetchTags = async () => {
      if (taxId) setTags(await getTags([taxId], searchQuery));
    };
    fetchTags();
  }, [refresh]);

  useEffect(() => {
    setSearchQuery(searchParams.get('searchQuery') || '');
    setRefresh(Date.now());
  }, [searchParams]);

  const handleDeleteClick = (item: ITag) => {
    setItemToDelete(item);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (itemToDelete) {
        const { taxId, tag } = itemToDelete;
        await removeTag(taxId, { tags: [tag] });
        setOperation({
          severity: OPERATIONS.SUCCESS,
          message: t('pages.operations.messages.tags-modified'),
        });
      }
    } catch (error) {
      setOperation({
        severity: OPERATIONS.ERROR,
        message: error.response.data.details,
      });
    } finally {
      setDeleteDialogOpen(false);
      setRefresh(Date.now());
    }
  };

  const handleDeleteCancel = () => {
    setItemToDelete(undefined);
    setDeleteDialogOpen(false);
  };

  const handleSearch = (searchQuery: string) => {
    clearTimeout(searchTimeout);
    setSearchQuery(searchQuery);
    const timeout = setTimeout(() => {
      setSearchParams({ searchQuery });
    }, SEARCH_INPUT_DELAY);
    setSearchTimeout(timeout);
  };

  return (
    <>
      <NodeContainer
        title={t('pages.tags.title')}
        subtitle={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box>
              <Typography>{t('pages.tags.subtitle')}</Typography>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ ml: '10px' }}>
                <ClassicButton
                  sx={{ mt: 0 }}
                  startIcon={<AddIcon />}
                  onClick={() =>
                    navigate(
                      ROUTE.dashboardPage.enterprise.addTag.replace(
                        ':taxId',
                        taxId ?? '',
                      ),
                      {
                        state: {
                          previousPath: ROUTE.dashboardPage.enterprise.root,
                        },
                      },
                    )
                  }
                >
                  {t('common.button.add-btn')}
                </ClassicButton>
              </Box>
            </Box>
          </Box>
        }
        cta={
          <ClassicInput
            fullWidth
            label={t('common.search-label.tags')}
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
        }
      >
        {tags.length === 0 ? (
          <NoData />
        ) : (
          <Grid container spacing={2} sx={{ py: 5 }}>
            {tags.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={6}>
                <Card elevation={3}>
                  <ListItem>
                    <ListItemText primary={item.tag} />
                    <IconButton onClick={() => handleDeleteClick(item)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </NodeContainer>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>{t('common.confirm-delete')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('common.confirm-delete-message')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>
            {t('common.button.cancel-btn')}
          </Button>
          <Button onClick={handleDeleteConfirm}>
            {t('common.button.confirm-btn')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
